import router from '@/router'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'

export const sentryInit = (app: App) => {
    Sentry.init({
        app,
        release: `mis-web@${import.meta.env.VITE_MIS_APP_VERSION}`,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        tracePropagationTargets: [/^https:\/\/madeinshow\.app/],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

        // Filtraggio eventi
        beforeSend: (event, hint) => {
            // Fatture: Metadato, già presente
            const originalException = hint.originalException as any
            if (
                originalException &&
                originalException.response &&
                originalException.response.status &&
                [
                    406, // Fattura già presente
                    409, // Metadato
                    424, // Method Failure, condizione non applicabile
                ].includes(originalException.response.status)
            ) {
                return null
            }
            return event
        },
    })
}

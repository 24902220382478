import { defineStore } from 'pinia'

import config from '@/config'
import { requestSelectors } from '@/modules/company-management/api/request-selectors'
import * as requestTypes from '@/modules/company-management/api/request-types'
import {
    getSchedulesFilterToIdx,
    processSchedule,
} from '@/modules/company-management/helpers/model-data-processing'
import type {
    CMSchedule,
    CMScheduleTotals,
} from '@/modules/company-management/models'
import { newSchedule } from '@/modules/company-management/models/constructors'
import { useInvoiceStore } from '@/modules/company-management/store/invoice-store'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { toDateStringObjBulk } from '@/modules/sls/helpers/dates-helpers'
import type { SLSAttachment } from '@/modules/sls/models'
import { axiosRequest } from '@/store/action-helpers'
import axios from 'axios'
import { state } from './invoice-schedule-store-state'

export const useInvoiceScheduleStore = defineStore('invoiceSchedule', {
    state,
    actions: {
        async GET_SCHEDULES(payload: requestTypes.GetSchedules) {
            await axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_SCHEDULES,
                    ...payload,
                })

                const data = response.data as CMSchedule[]

                const dataProcessed: CMSchedule[] = []

                // Salvo anche aggiornamento per id
                data.forEach((cost: CMSchedule) => {
                    cost = processSchedule(cost)

                    dataProcessed.push(cost)

                    this.schedules.byId[cost.schedule_id] = cost
                })

                const key = getSchedulesFilterToIdx(payload)
                this.schedules.filtered[key] = dataProcessed
            })
        },

        async UPDATE_SCHEDULE(payload: requestTypes.UpdateCost) {
            const invoiceStore = useInvoiceStore()
            const uiStore = useUiStore()

            const processedData = toDateStringObjBulk(payload, [
                'last_payment_date',
                'assumed_payment_date',
                'paid_date',
                'ref_date',
            ])

            await axiosRequest(
                async () => {
                    await axios.post(config().ENDPOINT, {
                        ...requestSelectors.UPDATE_SCHEDULE,
                        ...processedData,
                    })

                    if (payload.invoice_id) {
                        await invoiceStore.GET_INVOICE(payload.invoice_id)
                    }
                },
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
            )
        },

        async ADD_SCHEDULE(payload: requestTypes.AddCost) {
            const invoiceStore = useInvoiceStore()
            const uiStore = useUiStore()

            const processedData = toDateStringObjBulk(payload, [
                'last_payment_date',
                'assumed_payment_date',
                'paid_date',
                'ref_date',
            ])

            return axiosRequest(
                async () => {
                    const promise = axios.post(config().ENDPOINT, {
                        ...requestSelectors.ADD_SCHEDULE,
                        ...processedData,
                    })

                    if (payload.invoice_id) {
                        await promise

                        await invoiceStore.GET_INVOICE(payload.invoice_id)
                    }

                    return promise
                },
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
            )
        },
        async DEL_SCHEDULE(schedule_id: number) {
            await axiosRequest(async () => {
                await axios.post(config().ENDPOINT, {
                    ...requestSelectors.DEL_SCHEDULE,
                    schedule_id,
                })

                if (this.schedules.byId[schedule_id]) {
                    delete this.schedules.byId[schedule_id]
                }
            })
        },
        async GET_SCHEDULE_ATTACHMENTS(scheduleId: number) {
            await axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_SCHEDULE_ATTACHMENTS,
                    schedule_id: scheduleId,
                })

                const data = response.data as SLSAttachment[]

                if (!data && this.schedules.byId[scheduleId]) {
                    this.schedules.byId[scheduleId].attachments = []

                    return
                } else if (!data) {
                    return
                } else if (!this.schedules.byId[scheduleId]) {
                    this.schedules.byId[scheduleId] = newSchedule(false) // FIXME verificare
                }

                let item: SLSAttachment
                for (let i = 0; i < data.length; i++) {
                    item = data[i]
                    item.url =
                        config().ENDPOINT +
                        '?sec=cm&req=get_file&hash=' +
                        item.hash
                }

                this.schedules.byId[scheduleId].attachments.length = 0
                this.schedules.byId[scheduleId].attachments.push(...data)
            })
        },
        async DEL_SCHEDULE_ATTACHMENT(value: {
            hash: string
            schedule_id: number
        }) {
            const uiStore = useUiStore()

            await axiosRequest(
                async () => {
                    await axios.post(config().ENDPOINT, {
                        ...requestSelectors.DEL_SCHEDULE_ATTACHMENT,
                        hash: value.hash,
                    })
                    await this.GET_SCHEDULE_ATTACHMENTS(value.schedule_id)
                },
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
            )
        },

        // async GET_INVOICE_SCHEDULES(payload: requestTypes.GetInvoiceSchedule) {
        //     await axiosRequest(async () => {
        //         const response = await axios.post(config().ENDPOINT, {
        //             ...requestSelectors.GET_INVOICE_SCHEDULES,
        //             ...payload,
        //         })
        //
        //         // FIXME mai implementato
        //         // commit(MutationTypesLocal.SET_PASSIVE_INVOICE_COSTS, {
        //         //     ...payload,
        //         //     data: response.data,
        //         // })
        //     })
        // },

        async GET_SCHEDULE_TOTALS_BY_MONTH(year: number) {
            return axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_SCHEDULE_TOTALS_BY_MONTH,
                    year,
                })

                this.scheduleTotals[year] = response.data as {
                    by_month: CMScheduleTotals[]
                    by_date: CMScheduleTotals[]
                }
            })
        },
    },
    getters: {},
})

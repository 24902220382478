<script setup>
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { onBeforeMount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const uiStore = useUiStore()

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    index: {
        type: Number,
        default: 0,
    },
    root: {
        type: Boolean,
        default: true,
    },
    parentItemKey: {
        type: String,
        default: null,
    },
    rootIndex: {
        type: Number,
        default: 0,
    },
    iconBarItem: {
        type: Boolean,
        default: false,
    },
})

const isActiveMenu = ref(false)
const itemKey = ref(null)
const subMenuRef = ref(null)
const menuItemRef = ref(null)

onBeforeMount(() => {
    itemKey.value = props.parentItemKey
        ? props.parentItemKey + '-' + props.index
        : String(props.index)

    const activeItem = uiStore.activeMenuItem

    isActiveMenu.value =
        activeItem === itemKey.value || activeItem
            ? activeItem.startsWith(itemKey.value + '-')
            : false
})

watch(
    () => uiStore.activeMenuItem,
    (newVal) => {
        isActiveMenu.value =
            newVal === itemKey.value || newVal.startsWith(itemKey.value + '-')
    },
)

watch(
    () => uiStore.overlaySubmenuActive,
    (newValue) => {
        if (!newValue) {
            isActiveMenu.value = false
        }
    },
)

watch(
    () => route.path,
    (newPath) => {
        if (props.item.key && props.item.key === newPath) {
            uiStore.SET_ACTIVE_MENU_ITEM(itemKey)
        }
    },
)
const itemClick = async (event, item) => {
    if (item.disabled) {
        event.preventDefault()
        return
    }

    if (
        (item.key || item.url) &&
        (uiStore.staticMenuMobileActive || uiStore.overlayMenuActive)
    ) {
        uiStore.ON_MENU_TOGGLE()
    }

    if (item.command) {
        item.command({ originalEvent: event, item: item })
    }

    if (item.items) {
        uiStore.SET_ACTIVE_MENU_ITEM(
            isActiveMenu.value ? props.parentItemKey : itemKey,
        )
    } else {
        if (!uiStore.IS_DESKTOP) {
            uiStore.staticMenuMobileActive = !uiStore.staticMenuMobileActive
        }

        uiStore.SET_ACTIVE_MENU_ITEM(itemKey)
    }
}

const checkActiveRoute = (item) => {
    return route.path === item.key
}
</script>

<template>
    <li
        ref="menuItemRef"
        :class="{
            'layout-root-menuitem': root,
            'active-menuitem': !root && isActiveMenu,
        }"
    >
        <div
            v-if="
                root &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
            class="flex justify-content-between align-items-center"
            style="height: 2.5rem"
        >
            <div class="layout-menuitem-root-text pl-2">
                {{ item.label }}
            </div>

            <!--        Container icone-->
            <div class="flex column-gap-2">
                <template
                    v-for="(child, i) in item.iconBarItems"
                    :key="child"
                    v-if="item.iconBarItems"
                >
                    <app-menu-item
                        :index="i + 1000"
                        :item="child"
                        :parentItemKey="itemKey"
                        :root="false"
                        :rootIndex="props.index"
                        :iconBarItem="true"
                        v-if="
                            child.visible !== false &&
                            (typeof child.visible !== 'function' ||
                                child.visible())
                        "
                    ></app-menu-item>
                </template>

                <!--                Icona sezione-->
                <li
                    v-if="item.icon"
                    class="menu-root-section-icon h-2rem w-2rem border-circle align-items-center justify-content-center border-none text-xl"
                >
                    <span
                        :class="item.icon"
                        data-pc-section="icon"
                        v-tooltip.bottom="item.label"
                    ></span>
                </li>
            </div>
        </div>

        <a
            v-if="
                (!item.key || item.items) &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
            :href="item.url"
            @click="itemClick($event, item, index)"
            :class="item.class"
            :target="item.target"
            tabindex="0"
        >
            <!--            Icona sottosezione-->
            <!--            <span-->
            <!--                :class="-->
            <!--                    item.icon +-->
            <!--                    ' p-menuitem-icon layout-menuitem-icon text-center w-2rem'-->
            <!--                "-->
            <!--                data-pc-section="icon"-->
            <!--            ></span>-->
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <span
                class="far fa-angle-down layout-submenu-toggler"
                v-if="item.items"
            ></span>
        </a>

        <!--        Voce singola-->
        <router-link
            v-if="
                item.key &&
                !item.items &&
                !$props.iconBarItem &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
            @click="itemClick($event, item, index)"
            :class="[item.class, { 'active-route': checkActiveRoute(item) }]"
            tabindex="0"
            :to="item.key"
        >
            <!--            Icona sottosezione-->
            <!--            <span-->
            <!--                :class="-->
            <!--                    item.icon +-->
            <!--                    ' p-menuitem-icon layout-menuitem-icon text-center w-2rem'-->
            <!--                "-->
            <!--                data-pc-section="icon"-->
            <!--            ></span>-->
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <span
                class="far fa-angle-down layout-submenu-toggler"
                v-if="item.items"
                data-pc-section="icon"
            ></span>
        </router-link>

        <!--         Icona visibile-->
        <router-link
            v-if="
                item.key &&
                !item.items &&
                $props.iconBarItem &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
            @click="itemClick($event, item, index)"
            :class="[
                item.class,
                'routing-icon h-2rem w-2rem  border-circle surface-50 flex align-items-center justify-content-center border-none text-lg',
                { 'active-route': checkActiveRoute(item) },
            ]"
            tabindex="0"
            :to="item.key"
        >
            <span
                :class="item.icon"
                data-pc-section="icon"
                v-tooltip.bottom="item.label"
            ></span>
        </router-link>

        <!--        Menu a soffietto con altre voci-->
        <ul
            ref="subMenuRef"
            :class="{ 'layout-root-submenulist': root }"
            v-if="
                item.items &&
                !item.iconBar &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
        >
            <app-menu-item
                v-for="(child, i) in item.items"
                :key="child"
                :index="i"
                :item="child"
                :parentItemKey="itemKey"
                :root="false"
                :rootIndex="props.index"
            ></app-menu-item>
        </ul>
    </li>
</template>

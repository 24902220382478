import { defineStore } from 'pinia'

import config from '@/config'
import { requestSelectors } from '@/modules/company-management/api/request-selectors'
import * as requestTypes from '@/modules/company-management/api/request-types'
import {
    getInvoicesFilterToIdx,
    processInvoice,
    processSchedule,
} from '@/modules/company-management/helpers/model-data-processing'
import type {
    CMInvoice,
    CMInvoiceCategory,
    CMInvoiceRow,
    CMInvoiceStatus,
    CMSchedule,
} from '@/modules/company-management/models'
import { newInvoice } from '@/modules/company-management/models/constructors'
import { useInvoiceScheduleStore } from '@/modules/company-management/store/invoice-schedule-store'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { toDateStringObjBulk } from '@/modules/sls/helpers/dates-helpers'
import type { SLSAttachment, SLSHTTPException } from '@/modules/sls/models'
import { axiosRequest } from '@/store/action-helpers'
import axios, { AxiosError } from 'axios'
import { state } from './invoice-store-state'

export const useInvoiceStore = defineStore('invoices', {
    state,
    actions: {
        async GET_INVOICES(payload: requestTypes.GetInvoices) {
            await axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_INVOICES,
                    ...payload,
                })

                const data = response.data as CMInvoice[]

                const dataProcessed: CMInvoice[] = []

                data.forEach((invoice) => {
                    invoice = processInvoice(invoice)

                    invoice.schedules = []

                    dataProcessed.push(invoice)

                    // Salvo anche aggiornamento per id
                    this.invoices.byId[invoice.invoice_id] = invoice
                })

                const key = getInvoicesFilterToIdx(payload)
                this.invoices.filtered[key] = dataProcessed
            })
        },

        async GET_INVOICE(pInvoiceId: number) {
            const invoiceScheduleStore = useInvoiceScheduleStore()

            await axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_INVOICE,
                    invoice_id: pInvoiceId,
                })

                let invoice = response.data as CMInvoice

                if (!invoice) {
                    return
                }

                invoice = processInvoice(invoice)

                if (invoice.schedules) {
                    invoice.schedules.forEach((cost: CMSchedule) => {
                        cost = processSchedule(cost)

                        invoiceScheduleStore.schedules.byId[cost.schedule_id] =
                            cost
                    })
                }

                // Allegati
                if (this.invoices.byId[invoice.invoice_id]) {
                    invoice.attachments =
                        this.invoices.byId[invoice.invoice_id].attachments
                }

                this.invoices.byId[invoice.invoice_id] = invoice
            })
        },

        async GET_INVOICE_ATTACHMENTS(pInvoiceId: number) {
            await axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_INVOICE_ATTACHMENTS,
                    invoice_id: pInvoiceId,
                })

                const data = response.data as SLSAttachment[]

                if (!data && this.invoices.byId[pInvoiceId]) {
                    this.invoices.byId[pInvoiceId].attachments = []

                    return
                } else if (!data) {
                    return
                } else if (!this.invoices.byId[pInvoiceId]) {
                    this.invoices.byId[pInvoiceId] = newInvoice(
                        pInvoiceId,
                        false,
                    )
                }

                let item: SLSAttachment
                for (let i = 0; i < data.length; i++) {
                    item = data[i]
                    item.url =
                        config().ENDPOINT +
                        '?sec=cm&req=get_file&hash=' +
                        item.hash
                }

                this.invoices.byId[pInvoiceId].attachments.length = 0
                this.invoices.byId[pInvoiceId].attachments.push(...data)
            })
        },

        async DEL_INVOICE_ATTACHMENTS(payload: {
            hash: string
            invoice_id: number
        }) {
            const uiStore = useUiStore()

            await axiosRequest(
                async () => {
                    await axios.post(config().ENDPOINT, {
                        ...requestSelectors.DEL_INVOICE_ATTACHMENTS,
                        hash: payload.hash,
                    })
                    await this.GET_INVOICE_ATTACHMENTS(payload.invoice_id)
                },
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
            )
        },

        async ADD_INVOICE_MANUAL(payload: requestTypes.AddInvoice) {
            const uiStore = useUiStore()

            const processedData = toDateStringObjBulk(payload, [
                'last_payment_date',
                'header_date',
                'last_payment_reminder',
            ])

            return axiosRequest(
                async () =>
                    axios.post(config().ENDPOINT, {
                        ...requestSelectors.ADD_INVOICE_MANUAL,
                        ...processedData,
                    }),
                () =>
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    }),
            )
        },

        async UPDATE_INVOICE(payload: requestTypes.UpdateInvoice) {
            const uiStore = useUiStore()

            const processedData = toDateStringObjBulk(payload, [
                'paid_date',
                'last_payment_date',
                'header_date',
                'last_payment_reminder',
            ])

            await axiosRequest(
                async () => {
                    await axios.post(config().ENDPOINT, {
                        ...requestSelectors.UPDATE_INVOICE,
                        ...processedData,
                    })
                },
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
            )
        },

        async DEL_INVOICE(pInvoiceId: number) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    await axios.post(config().ENDPOINT, {
                        ...requestSelectors.DEL_INVOICE,
                        invoice_id: pInvoiceId,
                    }),
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
            )
        },

        async AUTO_SET_SUPPLIER(values: requestTypes.SetAutoSupplier) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    axios.post(config().ENDPOINT, {
                        ...requestSelectors.AUTO_SET_SUPPLIER,
                        ...values,
                    }),
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
            )
        },

        // Categorie
        async GET_INVOICE_CATEGORIES() {
            return axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_INVOICE_CATEGORIES,
                })

                this.activeInvoiceCategories.length = 0
                this.passiveInvoiceCategories.length = 0

                const categories = response.data as CMInvoiceCategory[]
                categories.forEach((category) => {
                    switch (category.limited_to) {
                        case 0:
                            this.activeInvoiceCategories.push(category)
                            this.passiveInvoiceCategories.push(category)
                            break

                        case 1:
                            this.activeInvoiceCategories.push(category)
                            break

                        case -1:
                            this.passiveInvoiceCategories.push(category)
                            break
                    }

                    this.categoriesById[category.category_id] = category
                })

                this.allInvoiceCategories = categories
            })
        },
        async ADD_INVOICE_CATEGORY(category: CMInvoiceCategory) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    axios.post(config().ENDPOINT, {
                        ...requestSelectors.ADD_INVOICE_CATEGORY,
                        ...category,
                    }),
                () =>
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    }),
                (e: AxiosError) => {
                    if (e?.response) {
                        console.error(e)
                        uiStore.ADD_TOAST({
                            severity: 'warn',
                            detail: (e.response.data as SLSHTTPException).error,
                        })
                    }
                },
            )
        },
        async UPDATE_INVOICE_CATEGORY(category: CMInvoiceCategory) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    axios.post(config().ENDPOINT, {
                        ...requestSelectors.UPDATE_INVOICE_CATEGORY,
                        ...category,
                    }),
                () =>
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    }),
                (e: AxiosError) => {
                    if (e?.response) {
                        console.error(e)
                        uiStore.ADD_TOAST({
                            severity: 'warn',
                            detail: (e.response.data as SLSHTTPException).error,
                        })
                    }
                },
            )
        },
        async DEL_INVOICE_CATEGORY(category_id: number) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    await axios.post(config().ENDPOINT, {
                        ...requestSelectors.DEL_INVOICE_CATEGORY,
                        category_id,
                    }),
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
                (e: AxiosError) => {
                    if (e?.response) {
                        console.error(e)
                        uiStore.ADD_TOAST({
                            severity: 'warn',
                            detail: (e.response.data as SLSHTTPException).error,
                        })
                    }
                },
            )
        },

        // Righe
        async GET_INVOICE_ROWS(invoice_id: number) {
            await axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_INVOICE_ROWS,
                    invoice_id,
                })

                const data = response.data as CMInvoiceRow[]

                if (!data && this.invoices.byId[invoice_id]) {
                    this.invoices.byId[invoice_id].rows = []

                    return
                } else if (!data) {
                    return
                } else if (!this.invoices.byId[invoice_id]) {
                    this.invoices.byId[invoice_id] = newInvoice(
                        invoice_id,
                        false,
                    )
                }

                this.invoices.byId[invoice_id].rows = data
            })
        },

        async ADD_INVOICE_ROW(row: CMInvoiceRow) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    axios.post(config().ENDPOINT, {
                        ...requestSelectors.ADD_INVOICE_ROW,
                        ...row,
                    }),
                () =>
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    }),
            )
        },

        async UPDATE_INVOICE_ROW(data: CMInvoiceRow) {
            return axiosRequest(async () => {
                await axios.post(config().ENDPOINT, {
                    ...requestSelectors.UPDATE_INVOICE_ROW,
                    ...data,
                })
            })
        },

        async DEL_INVOICE_ROW(invoice_row_id: number) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    await axios.post(config().ENDPOINT, {
                        ...requestSelectors.DEL_INVOICE_ROW,
                        invoice_row_id,
                    }),
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
            )
        },

        // Stati fattura
        async GET_INVOICE_STATUSES(get_metadata: boolean = false) {
            await axiosRequest(async () => {
                const response = await axios.post(config().ENDPOINT, {
                    ...requestSelectors.GET_INVOICE_STATUSES,
                    get_metadata,
                })

                if (get_metadata) {
                    this.invoiceVerificationStatusWithMetadata =
                        response.data as CMInvoiceStatus[]
                } else {
                    this.invoiceVerificationStatus =
                        response.data as CMInvoiceStatus[]
                }

                this.invoiceVerificationStatusById = {}
                this.invoiceVerificationStatus.forEach(
                    (item: CMInvoiceStatus) => {
                        this.invoiceVerificationStatusById[item.v_status_id] =
                            item
                    },
                )
            })
        },

        async ADD_INVOICE_STATUS(data: CMInvoiceStatus) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    axios.post(config().ENDPOINT, {
                        ...requestSelectors.ADD_INVOICE_STATUS,
                        ...data,
                    }),
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
                (e: AxiosError) => {
                    if (e?.response) {
                        console.error(e)
                        uiStore.ADD_TOAST({
                            severity: 'warn',
                            detail: (e.response.data as SLSHTTPException).error,
                        })
                    }
                },
            )
        },

        async UPDATE_INVOICE_STATUS(data: CMInvoiceStatus) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    axios.post(config().ENDPOINT, {
                        ...requestSelectors.UPDATE_INVOICE_STATUS,
                        ...data,
                    }),
                undefined,
                (e: AxiosError) => {
                    if (e?.response) {
                        console.error(e)
                        uiStore.ADD_TOAST({
                            severity: 'warn',
                            detail: (e.response.data as SLSHTTPException).error,
                        })
                    }
                },
            )
        },

        async DEL_INVOICE_STATUS(v_status_id: number) {
            const uiStore = useUiStore()

            return axiosRequest(
                async () =>
                    axios.post(config().ENDPOINT, {
                        ...requestSelectors.DEL_INVOICE_STATUS,
                        v_status_id,
                    }),
                () => {
                    uiStore.ADD_TOAST({
                        severity: 'success',
                        detail: 'Operazione completata con successo',
                    })
                },
                (e: AxiosError) => {
                    if (e?.response) {
                        console.error(e)
                        uiStore.ADD_TOAST({
                            severity: 'warn',
                            detail: (e.response.data as SLSHTTPException).error,
                        })
                    }
                },
            )
        },
    },
    getters: {
        INVOICE_VERIFICATION_END_STATUS(): number {
            for (let i = 0; i < this.invoiceVerificationStatus.length; i++) {
                if (
                    this.invoiceVerificationStatus[i]
                        .invoice_verification_end_status
                ) {
                    return this.invoiceVerificationStatus[i].v_status_id
                }
            }

            return -1
        },
    },
})

<script setup>
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { generateUrl } from '@/modules/sls/helpers/router-helpers'
import Button from 'primevue/button'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import AppMenu from './AppMenu.vue'

const uiStore = useUiStore()
const router = useRouter()

let timeout = null
const colorScheme = computed(() => uiStore.colorScheme)

const onMouseEnter = () => {
    if (!uiStore.anchored && document.hasFocus()) {
        if (timeout) {
            clearTimeout(timeout)
            timeout = null
        }
        uiStore.sidebarActive = true
    }
}

const onMouseLeave = () => {
    if (!uiStore.anchored) {
        if (!timeout) {
            timeout = setTimeout(() => (uiStore.sidebarActive = false), 300)
        }
    }
}

const closeMenu = () => {
    uiStore.ON_MENU_TOGGLE()
}
const anchor = () => {
    uiStore.anchored = !uiStore.anchored
}

const logoUrl = computed(() => generateUrl({}, ''))
const logoIconSrc = computed(
    () => import.meta.env.BASE_URL + 'img/brand/mis-logo-icon-dark.svg',
)
const logoSrc = computed(
    () => import.meta.env.BASE_URL + 'img/brand/mis-logo-dark.svg',
)

const lockIconSrc = computed(() =>
    uiStore.anchored ? 'far fa-lock' : 'far fa-lock-open',
)

const helpdeskUrl = import.meta.env.VITE_HELPDESK_URL
</script>

<template>
    <div
        class="layout-sidebar"
        @mouseenter="onMouseEnter()"
        @mouseleave="onMouseLeave()"
    >
        <div class="sidebar-header">
            <a :href="logoUrl" class="app-logo" style="cursor: pointer">
                <div class="app-logo-small h-2rem">
                    <img :src="logoIconSrc" alt="logo" role="presentation" />
                </div>
                <div class="app-logo-normal">
                    <img
                        class="h-2rem"
                        :src="logoSrc"
                        alt="logo"
                        role="presentation"
                    />
                </div>
            </a>
            <Button
                class="layout-sidebar-anchor z-2 mx-auto"
                type="button"
                :icon="lockIconSrc"
                @click="anchor()"
            ></Button>
            <Button
                class="lg:hidden z-2 far fa-times sls-button-close"
                type="button"
                @click="closeMenu()"
            ></Button>
        </div>

        <div class="layout-menu-container flex flex-column">
            <AppMenu class="flex-grow-1" />
        </div>
    </div>
</template>

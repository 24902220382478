import { AddressBookRouter } from '@/modules/address-book/router'
import { CompanyManagementRouter } from '@/modules/company-management/router'
import { CompanyVehiclesRouter } from '@/modules/company-vehicle/router'
import { DevRouter } from '@/modules/dev/router'
import { MessagingRouter } from '@/modules/messaging/router'
import { setRouteTitle } from '@/modules/primevue/helpers'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { ProductionsRouter } from '@/modules/production/router'
import { SalesRouter } from '@/modules/sales/router'
import { SLSRouter } from '@/modules/sls/router'
import { TechniciansRouter } from '@/modules/technician/router'
import { UsersRouter } from '@/modules/user/router'
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'
import { WarehouseRouter } from '@/modules/warehouse/router'
import {
    createRouter,
    createWebHashHistory,
    type RouteLocationNormalized,
    type RouteRecordRaw,
} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    ...AddressBookRouter,
    ...CompanyManagementRouter,
    ...CompanyVehiclesRouter,
    ...DevRouter,
    ...SalesRouter,
    ...MessagingRouter,
    ...ProductionsRouter,
    ...TechniciansRouter,
    ...UsersRouter,
    ...WarehouseRouter,
    ...SLSRouter,
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition
        else if (to.hash) return { el: to.hash }
        else return { top: 0 }
    },
    history: createWebHashHistory(),
    routes,
})

let requestedRoute: RouteLocationNormalized | null = null
router.beforeEach((to, from, next) => {
    const currentUserStore = useCurrentUserStore()
    const isAuthenticated = currentUserStore.IS_AUTHENTICATED

    const uiStore = useUiStore()

    if (to.meta && to.meta.title) {
        setRouteTitle(to.meta.title as string, true)
    }

    // Saves destination route as last valid route
    if (to.name !== 'Login') {
        requestedRoute = to
    }

    // Non mandare a login utenti autenticati
    if (to.name === 'Login' && isAuthenticated) {
        console.debug('Already authenticated, redirect')

        next(
            getLastRequestedRoute() ||
                currentUserStore.currentUser?.config.base_path ||
                import.meta.env.VITE_DEFAULT_HOME_PATH,
        )
        return
    }

    // Rotte senza autenticazione e utenti autenticati procedono
    if (to.matched.some((record) => record.meta.noAuth) || isAuthenticated) {
        uiStore.showBigLoader = false
        next()
        return
    }

    currentUserStore
        .FETCH_USER_DATA()
        .then(() => {
            if (currentUserStore.IS_AUTHENTICATED) {
                next()
            }
        })
        .finally(() => {
            uiStore.showBigLoader = false
        })
})

export const setLastRequestedRoute = (route: RouteLocationNormalized) => {
    requestedRoute = route
}

export const getLastRequestedRoute = () =>
    requestedRoute && requestedRoute.name !== 'Login'
        ? requestedRoute.path
        : null

export default router

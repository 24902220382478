import { defineStore } from 'pinia'

import config from '@/config'
import { requestSelectors } from '@/modules/sls/api/request-selectors'
import { axiosRequest } from '@/store/action-helpers'
import axios from 'axios'
import { state } from './instance-store-state'

export const useInstanceStore = defineStore('instance', {
    state,
    actions: {
        async GET_USERS_QUOTA() {
            await axiosRequest(async () => {
                const response = await axios.post(
                    config().ENDPOINT,
                    requestSelectors.GET_USERS_QUOTA,
                )

                this.usersQuota = response.data
            })
        },
    },
})

// Declare state
import { useInstanceStore } from '@/modules/sls/stores/instance-store'
import type { ConfirmationServiceMethods } from 'primevue/confirmationservice'
import type { ToastServiceMethods } from 'primevue/toastservice'

export type UiStoreState = {
    offline: boolean

    // PrimeVue
    staticMenuDesktopInactive: boolean
    overlayMenuActive: boolean
    rightMenuActive: boolean
    staticMenuMobileActive: boolean
    menuHoverActive: boolean
    sidebarActive: boolean
    anchored: boolean
    activeMenuItem: any
    rightMenuVisible: boolean
    overlaySubmenuActive: boolean

    // Tema
    colorScheme: string
    fontSize: string

    // Localization
    locale: string
    locale_full: string
    currency: string

    // SLS Big loader
    isLoading: boolean
    showBigLoader: boolean

    // Toast
    toastService: ToastServiceMethods | null

    // Confirmation
    confirmService: ConfirmationServiceMethods | null

    // Dipendenti dal modulo
    productionProductReservationsExtraColumnsShown: string[]
    productionProductReservationsCategoryTotals: boolean
    home: {
        switchPlanner: number
        switchShortcutWidget: number
    }

    // Ultima view utilizzata per ogni planner
    plannerLastView: { [key: string]: string }
    plannerTimelineMinStep: number

    // Dati di partenza del planner delle schede prodotto
    plannerProductSheetsStartData: {
        product_id: number
        maincat_id: number
        subcat_id: number
        start: Date
    } | null
}

// Create initial state
export const state = (): UiStoreState => {
    const instanceStore = useInstanceStore()

    return {
        offline: false,

        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        rightMenuActive: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        sidebarActive: false,
        anchored: false,
        activeMenuItem: 'home',
        rightMenuVisible: false,
        overlaySubmenuActive: false,

        colorScheme: import.meta.env.VITE_DEFAULT_COLOR_SCHEME,
        fontSize: import.meta.env.VITE_DEFAULT_FONT_SIZE,

        locale: 'it', // TODO instanceStore.LOCALE
        locale_full: 'it-IT', // TODO instanceStore.LOCALE
        currency: instanceStore.CURRENCY,

        isLoading: false,
        showBigLoader: true,

        toastService: null,
        confirmService: null,

        productionProductReservationsExtraColumnsShown: ['note', 'daily_price'],
        productionProductReservationsCategoryTotals: false,
        home: {
            switchPlanner: 1,
            switchShortcutWidget: 1,
        },

        plannerLastView: {},
        plannerTimelineMinStep: 40,
        plannerProductSheetsStartData: null,
    }
}

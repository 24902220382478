// Components
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/wh'

export default {
    path: `${basePath}/stats`,
    name: 'Statistiche magazzino',
    meta: {
        title: 'Statistiche magazzino',
    },
    component: () =>
        import('@/modules/warehouse/components/WarehouseStats.vue'),
    children: [
        {
            path: 'products',
            name: 'Statistiche prodotti a magazzino',
            meta: {
                title: 'Statistiche prodotti a magazzino',
            },
            component: () =>
                import(
                    '@/modules/warehouse/components/WarehouseProductsStats.vue'
                ),
        },
        {
            path: 'product-reservations',
            name: 'Statistiche prenotazioni prodotti',
            meta: {
                title: 'Statistiche prenotazioni prodotti',
            },
            component: () =>
                import(
                    '@/modules/warehouse/components/WarehouseProductReservationsStats.vue'
                ),
        },
        {
            path: 'export',
            name: 'Documenti esportabili magazzino',
            meta: {
                title: 'Documenti esportabili magazzino',
            },
            component: () =>
                import(
                    '@/modules/warehouse/components/WarehouseDataExport.vue'
                ),
        },
    ],
} as RouteRecordRaw

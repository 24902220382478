import { scrollToElement } from '@/modules/sls/helpers/animation-helpers'
import router from '@/router'
import type { MenuItem } from 'primevue/menuitem'

export function scrollToKeyAsId(this: MenuItem) {
    if (this.key) scrollToElement(`#${this.key}`)
}

export function goToKeyAsRoute(this: MenuItem) {
    if (this.key) return router.push(this.key)
}

export function checkMenuItemActive(
    regex: RegExp | string,
    activeClass: string = 'p-menuitem-active',
): string {
    let active: boolean

    if (typeof regex === 'string') {
        active = router.currentRoute.value.fullPath === regex
    } else {
        active = regex.test(router.currentRoute.value.fullPath)
    }

    return active ? activeClass : ''
}

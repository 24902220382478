import type { AddressBookSheet } from '@/modules/address-book/models'

const BASE_URL = '/ab'

export function toPageLink(personalDataId: number, tab?: string): string
export function toPageLink(item: AddressBookSheet, tab?: string): string
export function toPageLink(
    value: AddressBookSheet | number,
    tab?: string,
): string {
    let calcUrl
    if (typeof value === 'number') {
        calcUrl = `${BASE_URL}/${value}`
    } else if (value && value.personal_data_id) {
        calcUrl = `${BASE_URL}/${value.personal_data_id}`
    } else {
        return '#'
    }

    if (tab) {
        calcUrl += `/${tab}`
    }

    return calcUrl
}

import type {
    CMInvoice,
    CMInvoiceRow,
    CMInvoiceStatus,
    CMSchedule,
    CompanyManagementPaymentAccount,
} from '@/modules/company-management/models/index'

export const newInvoice = (invoiceId: number, active: boolean): CMInvoice => {
    return {
        customer_name: '',
        customer_pd_id: null,
        active,
        invoice_id: invoiceId,
        invoice_code: '',
        category_id: null,
        supplier_name: '',
        VAT_number: '',
        header_date: '',
        supplier_pd_id: null,
        payment_method_id: null,
        last_payment_date: null,
        assumed_payment_delay: 0,
        total_taxable: 0,
        total_taxes: 0,
        total_invoice: 0,
        document_type: '',
        json_data: '',
        is_from_sdi: false,
        verification_status: 0,
        is_foreign: false,
        schedules: [],
        attachments: [],
        notes: '',
        desc_business: '',
        export_accountant: false,
        last_payment_reminder: null,
    }
}

export const newSchedule = (
    active: boolean,
    pInvoiceId?: number,
): CMSchedule => {
    return {
        active,
        schedule_id: 0,
        invoice_id: pInvoiceId ?? null,
        supplier_pd_id: null,
        supplier_name: '',
        customer_pd_id: null,
        customer_name: '',
        category_id: null,
        description: '',
        amount: 0.0,
        last_payment_date: new Date(),
        assumed_payment_date: new Date(),
        payment_method_id: null,
        balanced: false,
        paid_date: null,
        ref_date: null,
        attachments: [],
    }
}

export const newPaymentAccount = (): CompanyManagementPaymentAccount => ({
    p_account_id: 0,
    name: '',
    active: true,
})

export const newInvoiceRow = (invoice_id: number): CMInvoiceRow => ({
    invoice_row_id: 0,
    invoice_id,
    quantity: null,
    row_code: null,
    description: null,
    measure_unit_id: null,
    unit_price: null,
    vat_code: null,
    vat_value: null,
    row_total: 0,
    order: 0,
})

export const newInvoiceStatus = (): CMInvoiceStatus => ({
    v_status_id: 0,
    description: '',
    background_color: '#FFFFFF',
    text_color: '#000000',
    imported_active_invoice_starting_status: false,
    imported_passive_invoice_starting_status: false,
    invoice_verification_end_status: false,
    show_in_menu: false,
    menu_label: '',
    order: 100,
})

import { format as formatDate } from 'date-fns'

/**
 * Converte una stringa in una Date con orario impostato a mezzanotte
 * @param value
 */
export const toDateObj = function <Type>(value: string | Type): Date | Type {
    return typeof value === 'string' ? new Date(value.replace('Z', '')) : value
}

/**
 * Converte una data in una stringa con orario impostato a mezzanotte, altrimenti ritorna il valore originale
 * @param value
 */
export const toDateStringObj = function <Type>(
    value: Date | Type,
): string | Type {
    if (value instanceof Date) {
        return formatDate(value, "yyyy-MM-dd'T00:00:00Z'")
    }

    return value
}

/**
 * Converte una stringa in una Date con orario
 * @param value
 */
export const toDateTimeObj = function <Type>(
    value: string | Type,
): Date | Type {
    return typeof value === 'string' ? new Date(value) : value
}

type BulkSourceObjToDate = { [keys: string]: string | Date | any }
type BulkSourceObjToString = { [keys: string]: Date | any }
type DateObjBulkDef = { key: string; isDate?: boolean }
/**
 * Converte le chiavi di un oggetto in Date con orario locale opzionale
 * @param obj
 * @param def
 */
export const toMixedDateObjBulk = (
    obj: BulkSourceObjToDate,
    def: DateObjBulkDef[],
): any => {
    def.forEach((key: DateObjBulkDef) => {
        if (typeof obj[key.key] === 'string' && key.isDate) {
            console.debug(
                'toMixedDateObjBulk - toDateObj - key:',
                key.key,
                ' - prevValue:',
                obj[key.key],
                ' - newValue:',
                toDateObj(obj[key.key]),
            )
            obj[key.key] = toDateObj(obj[key.key])
        } else {
            console.debug(
                'toMixedDateObjBulk - toDateTimeObj - key:',
                key.key,
                ' - prevValue:',
                obj[key.key],
                ' - newValue:',
                toDateTimeObj(obj[key.key]),
            )
            obj[key.key] = toDateTimeObj(obj[key.key])
        }
    })

    return obj
}

/**
 * Converte le chiavi di un oggetto in Date con orario impostato a mezzanotte
 * @param obj
 * @param def
 */
export const toDateObjBulk = (obj: BulkSourceObjToDate, def: string[]): any => {
    def.forEach((key: string) => {
        if (typeof obj[key] === 'string') {
            obj[key] = toDateObj(obj[key])
        }
    })

    return obj
}

/**
 * Converte le chiavi di un oggetto in Date con orario locale
 * @param obj
 * @param def
 */
export const toDateTimeObjBulk = (
    obj: BulkSourceObjToDate,
    def: string[],
): any => {
    def.forEach((key: string) => {
        if (typeof obj[key] === 'string') {
            obj[key] = toDateTimeObj(obj[key])
        }
    })

    return obj
}

/**
 * Converte le chiavi di un oggetto in stringhe con orario impostato a mezzanotte
 * @param obj
 * @param def
 */
export const toDateStringObjBulk = (
    obj: BulkSourceObjToString,
    def: string[],
): any => {
    def.forEach((key: string) => {
        obj[key] = toDateStringObj(obj[key])
    })

    return obj
}

/**
 * Calcola il quadrimestre corrispondente di un mese
 * @param month il numero del mese, 0-indexed
 */
export const monthToQuarter = (month: number): number => {
    switch (month) {
        case 0:
        case 1:
        case 2:
            return 1
        case 3:
        case 4:
        case 5:
            return 2
        case 6:
        case 7:
        case 8:
            return 3
        case 9:
        case 10:
        case 11:
            return 4

        default:
            throw new Error('Numero errato')
    }
}

import type { RouteRecordRaw } from 'vue-router'

const basePath = '/pr'

export default {
    path: `${basePath}/search`,
    name: 'Ricerca in produzioni',
    meta: {
        title: 'Ricerca in produzioni',
    },
    component: () =>
        import('@/modules/production/components/ProductionSearch.vue'),
    children: [
        {
            path: 'production-binder',
            name: 'Ricerca produzioni e raccoglitori',
            meta: {
                title: 'Ricerca produzioni e raccoglitori',
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/1fe101f0-af84-488d-8b96-d0506fe50834',
                        label: '1.1 - Ricercare una produzione, un raccoglitore',
                    },
                ],
            },

            component: () =>
                import(
                    '@/modules/production/components/ProductionBinderSearch.vue'
                ),
        },
        {
            path: 'ext-entry',
            name: 'Ricerca preventivi esterni',
            meta: {
                title: 'Ricerca preventivi esterni',
            },
            component: () =>
                import(
                    '@/modules/production/components/ProductionExtEntrySearch.vue'
                ),
        },
    ],
} as RouteRecordRaw

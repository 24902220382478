// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'
import type { RouteRecordRaw } from 'vue-router'
import productionBinderTourSheetRoutes from './_production-binder-tour-sheet'
import productionSheetRoutes from './_production-sheet'
import productionSearch from './_search'
import productionSettings from './_settings'
import productionStats from './_stats'

const basePath = '/pr'

let routes: Array<RouteRecordRaw> = [
    {
        path: '/productions',
        name: 'Produzioni Legacy',
        redirect: `${basePath}/planner`,
    },
    {
        path: basePath,
        name: 'Produzioni',
        redirect: `${basePath}/planner`,
        component: AppLayout,
        children: [
            {
                path: `${basePath}/planner`,
                name: 'Planner produzioni',
                meta: {
                    title: 'Planner Produzioni',

                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/1fe101f0-af84-488d-8b96-d0506fe50834',
                            label: '1.1 - Ricercare una produzione, un raccoglitore',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/7c7cbb2a-e6ba-4210-a4f9-84bf59478034',
                            label: '1.2 - Aggiungere una produzione: creazione nuova produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/7bb0df21-cf87-487d-a121-7cc0e12e8a8a',
                            label: '1.2.1 - Aggiungere una produzione: usa raccoglitore esistente',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/3b6b09d4-ebac-4482-8474-febf11447b47',
                            label: '1.3 - Modifica dati generali di una produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/3d6c008c-614d-43ab-a67a-4fe0e9b75deb',
                            label: '1.4 - Spostamento date del periodo di lavorazione di una produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/ec633273-a566-46e4-ac35-3dd98dee06ea',
                            label: '1.5 - Modifica delle tipologie di date di una produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/ec173fdf-17e2-4fc3-b9c3-f05f7c188b57',
                            label: '1.6 - Prenotare una tipologia di prodotto a magazzino su una produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/e4798be0-f361-4f70-a2f4-c85696a6c221',
                            label: '1.7 - Modificare o cancellare una prenotazione di prodotto a magazzino',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/ba5e2908-73f6-4dad-b29d-506fa2811ead',
                            label: '1.8 - Aggiungere un budget/extra di materiale su una produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/cdb88f64-8b6a-40b1-967c-9617a6658377',
                            label: '1.9 - Prenotare un tecnico su una produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/c382c947-9c57-49aa-b645-7dd58a81b016',
                            label: '1.10 - Modificare o cancellare una prenotazione di tecnico su una produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/7a7996eb-7bf7-49e3-8a17-c28e88c69275',
                            label: '1.11 - Aggiungere un budget/extra di servizi tecnici su una produzione',
                        },
                        {
                            url: 'https://www.guidejar.com/guides/68a85b98-4327-42fc-9f4f-53e839b6f6ef',
                            label: '1.12 - Aggiungere una location',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/584cf84f-a1a0-48ed-a21f-da63b86e6e05',
                            label: '1.13 - Creazione di una composizione squadra',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/7d359a22-955a-486c-b876-40f2686aa11d',
                            label: '1.14 - Riassunto per data',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/production/components/ProductionsPlanner.vue'
                    ),
            },
            {
                path: `${basePath}/tour/add`,
                name: 'Aggiungi tour',
                meta: {
                    title: 'Aggiungi Tour',
                },
                component: () =>
                    import('@/modules/production/components/tour/TourAdd.vue'),
            },
            {
                path: `${basePath}/production/add`,
                name: 'Aggiungi produzione',
                meta: {
                    title: 'Aggiungi Produzione',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/7c7cbb2a-e6ba-4210-a4f9-84bf59478034',
                            label: '1.2 - Aggiungere una produzione: creazione nuova produzione',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/7bb0df21-cf87-487d-a121-7cc0e12e8a8a',
                            label: '1.2.1 - Aggiungere una produzione: usa raccoglitore esistente',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/production/components/production/ProductionAdd.vue'
                    ),
            },

            {
                path: `${basePath}/production/list`,
                name: 'Elenco produzioni',
                meta: {
                    title: 'Elenco produzioni',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/1fe101f0-af84-488d-8b96-d0506fe50834',
                            label: '1.1 - Ricercare una produzione, un raccoglitore',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/production/components/ProductionsList.vue'
                    ),
            },

            // Gestione risposte inviti
            {
                path: `${basePath}/team-compositions-planner`,
                name: 'Planner gestione squadre',
                meta: {
                    title: 'Planner composizione squadre',
                    guides: [],
                },
                component: () =>
                    import(
                        '@/modules/production/components/TeamCompositionsPlanner.vue'
                    ),
            },
            {
                path: `${basePath}/production/:id/invite/:tcaId`,
                name: 'Invito produzione',
                meta: {
                    title: 'Gestisci invito',
                    isProduction: true,
                    guides: [],
                },
                component: () =>
                    import(
                        '@/modules/production/components/InviteResponse.vue'
                    ),
            },
            {
                path: `${basePath}/binder/:id/invite/:tcaId`,
                name: 'Invito raccoglitore',
                meta: {
                    title: 'Gestisci invito',
                    isBinder: true,
                    guides: [],
                },
                component: () =>
                    import(
                        '@/modules/production/components/InviteResponse.vue'
                    ),
            },
            {
                path: `${basePath}/tour/:id/invite/:tcaId`,
                name: 'Invito tour',
                meta: {
                    title: 'Gestisci invito',
                    isBinder: true,
                    guides: [],
                },
                component: () =>
                    import(
                        '@/modules/production/components/InviteResponse.vue'
                    ),
            },

            // Statistiche
            productionStats,

            // Impostazioni
            productionSettings,

            // Ricerca
            productionSearch,

            productionSheetRoutes,

            ...productionBinderTourSheetRoutes,
        ],
    },
]

export const ProductionsRouter = routes

export const arraySymmetricalDiff = (arrayA: any[], arrayB: any[]) => {
    const setA = new Set(arrayA)
    const setB = new Set(arrayB)

    const diffA = Array.from(setA).filter((x) => !setB.has(x))
    const diffB = Array.from(setB).filter((x) => !setA.has(x))

    return [...diffA, ...diffB]
}

/**
 * Processa un valore e ne restituisce un logico valore boolean
 * Gestisce TRUE, true, '1' e simili, fallback a !!
 * @param value
 */
export const booleanValue = (value: any): boolean => {
    if (typeof value === 'string') {
        if (['TRUE', 'YES', 'SI'].includes(value.trim().toUpperCase()))
            return true

        const parsedInt = parseInt(value)

        return isNaN(parsedInt) ? false : parsedInt > 0
    }

    return !!value
}

// Components
import DefaultLayout from '@/modules/primevue/components/AppLayout.vue'
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/sl'

export const SalesRouter: Array<RouteRecordRaw> = [
    {
        path: basePath,
        name: 'Home commerciale',
        redirect: `${basePath}/descriptions`,
        component: DefaultLayout,
        children: [
            {
                path: `${basePath}/descriptions`,
                name: 'Descrizioni commerciali',
                meta: {
                    title: 'Descrizioni commerciali',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/844e2a28-5992-4250-b575-8b65e4173665',
                            label: '7.2 - Aggiungere descrizione commerciale',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/sales/components/SalesDescriptionsIndex.vue'
                    ),
            },

            // Impostazioni
            {
                path: `${basePath}/settings`,
                name: 'Impostazioni commerciale',
                meta: {
                    title: 'Impostazioni commerciale',
                },
                component: () =>
                    import('@/modules/sales/components/SalesSettings.vue'),
                children: [
                    {
                        path: 'categories',
                        name: 'Gestisci categorie descrizioni',
                        meta: {
                            title: 'Gestisci categorie descrizioni',
                            guides: [
                                {
                                    url: 'https://howto.madeinshow.app/guides/33485c85-901c-4afc-8d61-68faeb6ccf22',
                                    label: '7.1 - Aggiungere una categoria di descrizione commerciale',
                                },
                            ],
                        },
                        component: () =>
                            import(
                                '@/modules/sales/components/SalesDescriptionCategoriesIndex.vue'
                            ),
                    },
                ],
            },
        ],
    },
]

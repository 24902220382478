import type {
    GetInvoices,
    GetSchedules,
} from '@/modules/company-management/api/request-types'
import type { CMInvoice, CMSchedule } from '@/modules/company-management/models'
import { toDateObjBulk } from '@/modules/sls/helpers/dates-helpers'

export function processInvoice(data: any): CMInvoice {
    // Date
    data = toDateObjBulk(data, [
        'header_date',
        'last_payment_date',
        'last_payment_reminder',
    ])

    // JSON
    data.json_data =
        typeof data.json_data === 'string'
            ? JSON.parse(data.json_data)
            : data.json_data

    data.attachments = []

    return data as CMInvoice
}

export function processSchedule(data: any): CMSchedule {
    data = toDateObjBulk(data, [
        'last_payment_date',
        'assumed_payment_date',
        'paid_date',
        'ref_date',
    ])

    data.attachments = []

    return data as CMSchedule
}

export function invoiceAddress(invoice: CMInvoice): string {
    return [
        invoice.invoice_address,
        invoice.invoice_address_num,
        invoice.invoice_post_code,
        invoice.invoice_city,
        invoice.invoice_province,
        invoice.invoice_country,
    ]
        .reduce((acc, value) => {
            if (typeof value === 'string' && value.length > 0) {
                acc.push(value)
            }
            return acc
        }, [] as string[])
        .join(', ')
}

export function getInvoicesFilterToIdx(filters: GetInvoices): string {
    const partialKey = []

    if (typeof filters.active === 'boolean') {
        partialKey.push('active-' + +filters.active)
    }
    if (filters.from_date && filters.to_date) {
        partialKey.push(filters.from_date + '-' + filters.to_date)
    }
    if (typeof filters.verification_status === 'number') {
        partialKey.push('verification-status-' + filters.verification_status)
    }
    if (typeof filters.is_foreign === 'boolean') {
        partialKey.push('is-foreign-' + +filters.is_foreign)
    }
    if (typeof filters.category_id === 'number') {
        partialKey.push('cat-' + +filters.category_id)
    }
    if (typeof filters.supplier_pd_id === 'number') {
        partialKey.push('supplier-id-' + +filters.supplier_pd_id)
    }
    if (typeof filters.supplier_name === 'string') {
        partialKey.push('supplier-' + encodeURIComponent(filters.supplier_name))
    }
    if (typeof filters.VAT_number === 'string') {
        partialKey.push('vat-' + encodeURIComponent(filters.VAT_number))
    }
    if (typeof filters.document_type === 'string') {
        partialKey.push('doctype-' + filters.document_type)
    }
    if (typeof filters.payment_method_id === 'number') {
        partialKey.push('pay-id-' + +filters.payment_method_id)
    }
    if (typeof filters.invoice_code === 'string') {
        partialKey.push('code-' + filters.invoice_code)
    }
    if (typeof filters.export_accountant === 'boolean') {
        partialKey.push('tbe-' + +filters.export_accountant)
    }
    if (typeof filters.is_from_sdi === 'boolean') {
        partialKey.push('sdi-' + +filters.is_from_sdi)
    }
    if (typeof filters.is_paid === 'boolean') {
        partialKey.push('paid-' + +filters.is_paid)
    }

    if (partialKey.length) return partialKey.join('_')

    throw new Error('Filtraggio non valido')
}

export function getSchedulesFilterToIdx(filters: GetSchedules): string {
    const partialKey = []

    if (typeof filters.active === 'boolean') {
        partialKey.push('active-' + +filters.active)
    }
    if (filters.from_date && filters.to_date) {
        partialKey.push(filters.from_date + '-' + filters.to_date)
    }
    if (filters.from_ref_date && filters.to_ref_date) {
        partialKey.push(filters.from_ref_date + '-' + filters.to_ref_date)
    }
    if (filters.paid_from_date && filters.paid_to_date) {
        partialKey.push(filters.paid_from_date + '-' + filters.paid_to_date)
    }
    if (typeof filters.category_id === 'number') {
        partialKey.push('cat-' + +filters.category_id)
    }
    if (typeof filters.supplier_pd_id === 'number') {
        partialKey.push('supplier-id-' + +filters.supplier_pd_id)
    }
    if (typeof filters.supplier_name === 'string') {
        partialKey.push('supplier-' + encodeURIComponent(filters.supplier_name))
    }
    if (typeof filters.payment_method_id === 'number') {
        partialKey.push('pay-id-' + +filters.payment_method_id)
    }
    if (typeof filters.payment_status === 'boolean') {
        partialKey.push('paid-' + +filters.payment_status)
    }
    if (typeof filters.has_invoice === 'boolean') {
        partialKey.push('has-invoice-' + +filters.has_invoice)
    }
    if (typeof filters.document_type === 'string') {
        partialKey.push('doctype-' + filters.document_type)
    }

    if (partialKey.length) return partialKey.join('_')

    throw new Error('Filtraggio non valido')
}

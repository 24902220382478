// Declare state
import i18nPlugin from '@/locales'
import type { QuillFont } from '@/modules/primevue/models'
import type { SLSUsersUsage } from '@/modules/sls/models'
import merge from 'lodash/merge'

export type InstanceStoreState = {
    axiosAbortController: AbortController

    COMPANY_PD_ID: number
    COMPANY_NAME: string

    DELIVERY_NOTE_DEFAULTS: {
        GENERATION_DAY_BEFORE_WORK_START: boolean
        DELIVERY_ON_WORK_START: boolean
        DELIVERY_HOUR: number
        DELIVERY_MINUTES: number
        DESTINATION_IS_LOCATION: boolean
        REASON: number
        APPEARANCE: number
        CARRIER: number
    }

    MIS_VERSION: string
    DEMO_VERSION: boolean
    DEMO_VERSION_EXPIRATION_DATE: Date | string | null

    I18N_OVERRIDE: {
        [key: string]: { [key: string]: { [key: string]: string } }
    }

    // Quote
    usersQuota: SLSUsersUsage

    // TODO da ricevere
    CURRENCY: string
    SHOW_STAGING_BLOCKS: boolean

    // TODO da ricontrollare
    edition: 'services'
    devVersion: boolean
    warehouseInventoriedDate: boolean
    companyManagementEstimateCat: number

    estimateFonts: QuillFont[]

    defaultTourStatusId: number
    checkInvoiceableStatus: boolean
    invoiceableStatusId: number
    refundCalculatorDefaultPricePerKm: number
    refundCalculatorDefaultCategoryId: number

    // Deprecate
    // productionCancelledId: never
}
type SLSWindow = Window &
    typeof globalThis & { CLIENTCONFIG: Partial<InstanceStoreState> }
const CLIENTCONFIG = (window as SLSWindow).CLIENTCONFIG ?? {}

// Data scadenza demo
if (typeof CLIENTCONFIG.DEMO_VERSION_EXPIRATION_DATE === 'string') {
    CLIENTCONFIG.DEMO_VERSION_EXPIRATION_DATE = new Date(
        CLIENTCONFIG.DEMO_VERSION_EXPIRATION_DATE,
    )
}
if (CLIENTCONFIG.I18N_OVERRIDE && CLIENTCONFIG.I18N_OVERRIDE['it-IT']) {
    ;(i18nPlugin.global as any).mergeLocaleMessage(
        'it-IT',
        CLIENTCONFIG.I18N_OVERRIDE['it-IT'],
    )
}

console.log('Made in Show versione ' + import.meta.env.VITE_MIS_APP_VERSION)

// Create initial state
const defaults: InstanceStoreState = {
    axiosAbortController: new AbortController(),

    COMPANY_PD_ID: 0,
    COMPANY_NAME: '',

    DELIVERY_NOTE_DEFAULTS: {
        GENERATION_DAY_BEFORE_WORK_START: true,
        DELIVERY_ON_WORK_START: true,
        DELIVERY_HOUR: 8,
        DELIVERY_MINUTES: 0,
        DESTINATION_IS_LOCATION: true,
        REASON: 1,
        APPEARANCE: 1,
        CARRIER: 1,
    },

    MIS_VERSION: import.meta.env.VITE_MIS_APP_VERSION,
    DEMO_VERSION: false,
    DEMO_VERSION_EXPIRATION_DATE: null,
    I18N_OVERRIDE: {},
    usersQuota: {
        current_users: 0,
        max_users: null,
        remaining_users: null,
    },

    // TODO da ricevere
    CURRENCY: 'EUR',

    SHOW_STAGING_BLOCKS: true,

    // TODO da ricontrollare
    edition: 'services',
    devVersion: false,
    companyManagementEstimateCat: 22,
    warehouseInventoriedDate: false,
    estimateFonts: [
        { name: 'Arial', value: 'arial' },
        { name: 'Times New Roman', value: 'times' },
    ],
    defaultTourStatusId: 1,
    checkInvoiceableStatus: false,
    invoiceableStatusId: 6,
    refundCalculatorDefaultPricePerKm: 0.5,
    refundCalculatorDefaultCategoryId: 7,
}

export const state = (): InstanceStoreState => merge({}, defaults, CLIENTCONFIG)

import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'
import router from '@/router'
import {
    CognitoUserPool,
    CognitoUserSession,
    CookieStorage,
    type ChallengeName,
    type IAuthenticationCallback,
    type ICookieStorageData,
} from 'amazon-cognito-identity-js'
import axios from 'axios'

export const getStorage = () =>
    new CookieStorage({
        domain: import.meta.env.VITE_COGNITO_COOKIE_DOMAIN,
        expires: 1,
        secure: true,
        sameSite: 'lax',
    })

let cognitoUserPool: CognitoUserPool | null = null
const getCognitoUserPool = () => {
    if (cognitoUserPool === null)
        cognitoUserPool = new CognitoUserPool({
            UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
            ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
            Storage: getStorage(),
        })

    return cognitoUserPool
}

export const setAuthorizationHeader = () => {
    const currentUserStore = useCurrentUserStore()

    axios.defaults.headers.common.Authorization =
        'Bearer ' +
        currentUserStore.cognitoSession?.getAccessToken().getJwtToken()
}
export const startTokenRefreshHandler = () => {
    const currentUserStore = useCurrentUserStore()

    const currentTimeSeconds = Math.floor(+new Date() / 1000)
    const idTokenExpire =
        currentUserStore.cognitoSession?.getAccessToken().getExpiration() ?? 0

    const remainingSeconds =
        idTokenExpire -
        currentTimeSeconds -
        parseInt(import.meta.env.VITE_COGNITO_ID_TOKEN_REFRESH_MARGIN_SEC)

    console.debug('COGNITO: Refresh token tra %d SEC', remainingSeconds)

    setTimeout(
        currentUserStore.REFRESH_COGNITO_SESSION,
        remainingSeconds > 0 ? remainingSeconds * 1000 : 0,
    )
}

export const cognitoAuthenticationCallback = (
    resolve?: (value: void) => void,
    reject?: (reason?: any) => void,
): IAuthenticationCallback => {
    const currentUserStore = useCurrentUserStore()

    return {
        onSuccess: (
            session: CognitoUserSession,
            userConfirmationNecessary?: boolean,
        ) => {
            console.debug('onSuccess', session)
            currentUserStore.cognitoSession = session

            setAuthorizationHeader()
            if (resolve) resolve()
        },
        onFailure: (err: any) => {
            console.debug('onFailure', err)
            if (reject) reject(err)
        },
        newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
            console.debug(
                'newPasswordRequired',
                userAttributes,
                requiredAttributes,
            )
            currentUserStore.cognitoStatus = {
                userAttributes,
                requiredAttributes,
            }
            return router.push('/complete-user-profile')
        },
        mfaRequired: (
            challengeName: ChallengeName,
            challengeParameters: any,
        ) => {
            console.debug('mfa required', challengeName, challengeParameters)
        },
        totpRequired: (
            challengeName: ChallengeName,
            challengeParameters: any,
        ) => {
            console.debug('totp required', challengeName, challengeParameters)
        },
        customChallenge: (challengeParameters: any) => {
            console.debug('custom challenge', challengeParameters)
        },
        mfaSetup: (challengeName: ChallengeName, challengeParameters: any) => {
            console.debug('mfa setup', challengeName, challengeParameters)
        },
        selectMFAType: (
            challengeName: ChallengeName,
            challengeParameters: any,
        ) => {
            console.debug('select mfa type', challengeName, challengeParameters)
        },
    }
}

export default getCognitoUserPool

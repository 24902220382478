import type { SLSApiRequest } from '@/modules/sls/models'

export const requestSelectors = {
    GET: { sec: 'usr', req: 'status' },
    LOGIN: { sec: 'usr', req: 'login' },
    LOGOUT: { sec: 'usr', req: 'logout' },
    GET_USERS: {
        sec: 'usr',
        req: 'get_users',
    },
    GET_ACTIVE_SUPERVISOR_USERS: {
        sec: 'usr',
        req: 'get_active_supervisor_users',
    },
    ADD_USER: {
        sec: 'usr',
        req: 'add_user',
    },
    UPDATE_USER: {
        sec: 'usr',
        req: 'update_user',
    },
    DEL_USER: {
        sec: 'usr',
        req: 'del_user',
    },

    GET_GROUP_PERM_PRESETS: {
        sec: 'usr',
        req: 'get_group_perm_presets',
    },
    GET_AVAILABLE_REQUESTS: {
        sec: 'usr',
        req: 'get_available_requests',
    },
    GET_GROUP_PERMS: {
        sec: 'usr',
        req: 'get_group_perms',
    },
    UPDATE_GROUP_PERMS: {
        sec: 'usr',
        req: 'update_group_perms',
    },
    GET_GROUPS: {
        sec: 'usr',
        req: 'get_groups',
    },
    ADD_GROUP: {
        sec: 'usr',
        req: 'add_group',
    },
    DEL_GROUP: {
        sec: 'usr',
        req: 'del_group',
    },
    RENAME_GROUP: {
        sec: 'usr',
        req: 'rename_group',
    },
} as { [key: string]: SLSApiRequest }

export const scrollToElement = (
    selector: string,
    options: { offset: number } = { offset: 24 },
) => {
    const element = document.querySelector(selector)

    if (element) {
        const topOffset =
            element.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            options.offset

        window.scrollTo({
            behavior: 'smooth',
            top: topOffset,
        })
    }
}

export const scrollIntoView = (selector: string) => {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'end' })
}

<template lang="pug">
//- transition(name="fade" mode="out-in")
if showBigLoader
    .layout-preloader-container
        .layout-preloader
            span
router-view
toast
ConfirmDialog#global-confirm-dialog
</template>

<script lang="ts">
import { t } from '@/locales'
import { en as primeVueEnUS } from '@/modules/primevue/locales/en-US.json'
import { it as primeVueItIT } from '@/modules/primevue/locales/it-IT.json'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'
import { setDefaultOptions } from 'date-fns'
import { enUS, it } from 'date-fns/locale'
import { usePrimeVue } from 'primevue/config'
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { computed, ref, watch, watchEffect } from 'vue'

export default {
    name: 'App',
    components: { Toast, ConfirmDialog },
    setup() {
        const uiStore = useUiStore()
        const userStore = useCurrentUserStore()
        const PrimeVue = usePrimeVue()

        uiStore.SET_TOAST_SERVICE(useToast())
        uiStore.SET_CONFIRM_SERVICE(useConfirm())

        // Language locale
        watch(
            () => uiStore.locale,
            () => {
                if (uiStore.locale === 'it') {
                    setDefaultOptions({ locale: it })
                    PrimeVue.config.locale = primeVueItIT
                } else {
                    setDefaultOptions({ locale: enUS })
                    PrimeVue.config.locale = primeVueEnUS
                }
            },
            { immediate: true },
        )

        if (import.meta.env.VITE_LOGIN_PROVIDER === 'cognito') {
            userStore
                .GET_COGNITO_SESSION()
                .then(() => {
                    userStore.FETCH_USER_DATA()
                })
                .catch(() => {
                    window.location = import.meta.env.VITE_COGNITO_LOGIN_URL
                })
        } else {
            setInterval(
                function () {
                    userStore.FETCH_USER_DATA()
                },
                parseInt(import.meta.env.VITE_POLLING_INTERVAL_S ?? 30) * 1000,
            )
        }

        // Auto aggiornamento SW
        const softwareUpdateIntervalS =
            import.meta.env.VITE_SW_UPDATE_INTERVAL_S &&
            parseInt(import.meta.env.VITE_SW_UPDATE_INTERVAL_S)

        const swActivated = ref(false)

        function registerPeriodicSync(
            swUrl: string,
            r: ServiceWorkerRegistration,
        ) {
            setInterval(async () => {
                if ('onLine' in navigator && !navigator.onLine) return

                try {
                    const resp = await fetch(swUrl, {
                        cache: 'no-store',
                        headers: {
                            cache: 'no-store',
                            'cache-control': 'no-cache',
                        },
                    })

                    if (resp?.status === 200) await r.update()
                } catch (e) {
                    console.error('error SW')
                }
            }, softwareUpdateIntervalS * 1000)
        }
        const { needRefresh, updateServiceWorker } = useRegisterSW({
            immediate: true,
            onRegisteredSW(swUrl, r) {
                if (r?.active?.state === 'activated') {
                    swActivated.value = true
                    registerPeriodicSync(swUrl, r)
                } else if (r?.installing) {
                    r.installing.addEventListener('statechange', (e: Event) => {
                        const sw: ServiceWorker = e.target as ServiceWorker
                        swActivated.value = sw.state === 'activated'
                        if (swActivated.value) registerPeriodicSync(swUrl, r)
                    })
                }
            },
        })

        watchEffect(() => {
            if (needRefresh.value) {
                uiStore.ASK_CONFIRM({
                    header: t('SLS.new_version_short'),
                    message: t('SLS.new_version_available'),
                    acceptLabel: t('SLS.update'),
                    rejectLabel: t('SLS.close'),
                    accept: () => {
                        updateServiceWorker().catch((error: any) => {
                            console.error('Errore aggiornamento SW', error)
                            uiStore.ADD_TOAST({
                                severity: 'error',
                                detail: t('SLS.new_version_update_error'),
                            })
                        })
                    },
                })
            }
        })

        return {
            showBigLoader: computed(() => uiStore.showBigLoader),
            showLoader: computed(() => uiStore.isLoading),
        }
    },
}
</script>

<style lang="scss">
// organize-imports-ignore
// Import Main styles for this application
@import 'styles/style';
</style>

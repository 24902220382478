import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/pr'

const binderPathsGenerator = (
    prefix: string,
    extraChildren: RouteRecordRaw[],
): RouteRecordRaw => ({
    path: `${basePath}/${prefix}/:id`,
    name: `Raccoglitori ${prefix}`,
    meta: {
        // title: '◌',
    },
    redirect: (to) => removeTrailingSlash(to.fullPath) + '/general',
    component: () =>
        import('@/modules/production/components/binder/BinderSheet.vue'),
    children: [
        ...extraChildren,
        {
            path: 'attachments',
            name: `Allegati raccoglitore ${prefix}`,
            component: () =>
                import(
                    '@/modules/production/components/binder/BinderAttachments.vue'
                ),
        },
        {
            path: 'dates-management',
            name: `Scheda gestione date raccoglitore ${prefix}`,
            component: () =>
                import(
                    '@/modules/production/components/binder/BinderDatesManagement.vue'
                ),
        },
        {
            path: 'summary-by-date',
            name: `Scheda riassunto per data raccoglitore ${prefix}`,
            meta: {
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/7d359a22-955a-486c-b876-40f2686aa11d',
                        label: '1.14 - Riassunto per data',
                    },
                ],
            },
            component: () =>
                import(
                    '@/modules/production/components/binder/BinderSummaryByDate.vue'
                ),
        },
        {
            path: 'teams',
            name: `Scheda gestione squadre raccoglitore ${prefix}`,
            component: () =>
                import(
                    '@/modules/production/components/binder/BinderTeamCompositionIndex.vue'
                ),
        },
        {
            path: 'team/:tcsId',
            name: `Scheda gestione squadra raccoglitore ${prefix}`,
            component: () =>
                import(
                    '@/modules/production/components/binder/BinderTeamComposition.vue'
                ),
        },
        {
            path: 'technicians-list',
            name: `Scheda gestione tecnici per raccoglitore ${prefix}`,
            component: () =>
                import(
                    '@/modules/production/components/binder/BinderTechinicianReservation.vue'
                ),
        },
        {
            path: 'ee/:entryTypeId',
            name: `Scheda forniture esterne raccoglitore ${prefix}`,
            component: () =>
                import(
                    '@/modules/production/components/binder/BinderExtEntries.vue'
                ),
        },
    ],
})

export default [
    binderPathsGenerator('tour', [
        {
            path: 'general',
            name: `Scheda raccoglitore generale tour`,
            component: () =>
                import('@/modules/production/components/tour/TourGeneral.vue'),
        },
    ]),
    binderPathsGenerator('binder', [
        {
            path: 'general',
            name: `Scheda raccoglitore generale binder`,
            component: () =>
                import(
                    '@/modules/production/components/binder/BinderGeneral.vue'
                ),
        },
    ]),
] as RouteRecordRaw[]

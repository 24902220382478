import { defineStore } from 'pinia'

import type { ConfirmationOptions } from 'primevue/confirmationoptions'
import type { ConfirmationServiceMethods } from 'primevue/confirmationservice'
import type { ToastMessageOptions } from 'primevue/toast'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { useWindowSize } from 'vue-window-size'
import { state, type UiStoreState } from './ui-store-state'

const replaceLink = (
    linkElement: HTMLElement,
    href: string,
    onComplete: CallableFunction,
) => {
    if (!linkElement || !href) {
        return
    }

    const id = linkElement.getAttribute('id')
    const cloneLinkElement = linkElement.cloneNode(true) as HTMLElement
    if (!cloneLinkElement || !id) return

    cloneLinkElement.setAttribute('href', href)
    cloneLinkElement.setAttribute('id', id + '-clone')

    const linkElementParent = linkElement.parentNode
    if (!linkElementParent) return

    linkElementParent.insertBefore(cloneLinkElement, linkElement.nextSibling)

    cloneLinkElement.addEventListener('load', () => {
        linkElement.remove()

        const element = document.getElementById(id) // re-check
        element && element.remove()

        cloneLinkElement.setAttribute('id', id)
        onComplete && onComplete()
    })
}

export const useUiStore = defineStore('ui', {
    persist: {
        afterHydrate: (ctx) => {
            // Font size
            ctx.store.SET_FONT_SIZE(
                ctx.store.fontSize ?? import.meta.env.VITE_DEFAULT_FONT_SIZE,
            )

            const defaultColorScheme = import.meta.env.VITE_DEFAULT_COLOR_SCHEME
            const currentColorScheme = ctx.store.colorScheme
            console.debug('PINIA: Ricaricato store UI')

            // Il tema è già corretto
            if (defaultColorScheme === currentColorScheme) {
                console.debug(`PINIA: Tema di default: ${defaultColorScheme}`)
                return
            }

            // Trigger cambio tema
            console.debug(`PINIA: Passaggio al tema: ${ctx.store.colorScheme}`)
            ctx.store.colorScheme = ctx.store.CHANGE_COLOR_SCHEME(
                ctx.store.colorScheme,
                ctx.store.colorScheme === 'light' ? 'dark' : 'light',
            )
        },
    },
    state,
    actions: {
        ADD_TOAST(toast: ToastMessageOptions) {
            // Preset
            if (
                toast.severity === 'success' &&
                typeof toast.life === 'undefined'
            ) {
                toast.life = 3000
            }

            this.toastService?.add(toast)
        },
        CLEAR_TOASTS() {
            this.toastService?.removeAllGroups()
        },
        SET_TOAST_SERVICE(toasts: ToastServiceMethods) {
            this.toastService = toasts
        },
        ASK_CONFIRM(
            confirm: ConfirmationOptions & {
                severity?:
                    | 'secondary'
                    | 'success'
                    | 'info'
                    | 'warning'
                    | 'help'
                    | 'danger'
                    | 'contrast'
            },
        ) {
            if (!confirm.icon) {
                switch (confirm.severity) {
                    case 'warning':
                        confirm.icon = 'far fa-exclamation-triangle'
                        break

                    default:
                        confirm.icon = 'far fa-question-circle'
                        break
                }
            }

            if (confirm.severity) {
                confirm.acceptClass = `p-button-${confirm.severity}`
                delete confirm.severity
            }

            this.confirmService?.require(confirm)
        },
        SET_CONFIRM_SERVICE(confirm: ConfirmationServiceMethods) {
            this.confirmService = confirm
        },
        SET_FONT_SIZE(fontSize: string) {
            this.fontSize = fontSize

            document.documentElement.style.setProperty(
                '--sls-font-size',
                fontSize,
            )
        },
        CHANGE_COLOR_SCHEME(colorScheme: string, fromColorScheme?: string) {
            const themeLink = document.getElementById('theme-link')
            if (!themeLink) {
                console.error('LINK TEMA MANCANTE')
                return
            }

            const themeLinkHref = themeLink.getAttribute('href')
            if (!themeLinkHref) {
                console.error('HREF TEMA MANCANTE')
                return
            }

            const currentColorScheme =
                'theme-' + (fromColorScheme ?? this.colorScheme.toString())
            const newColorScheme = 'theme-' + colorScheme
            const newHref = themeLinkHref.replace(
                currentColorScheme,
                newColorScheme,
            )

            replaceLink(themeLink, newHref, () => {
                this.colorScheme = colorScheme
            })
        },
        SET_ACTIVE_MENU_ITEM(item: any) {
            this.activeMenuItem = item.value || item
        },
        ON_MENU_TOGGLE() {
            if (this.IS_DESKTOP()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive
            }
        },
        IS_DESKTOP() {
            const { width } = useWindowSize()

            return width.value > 991
        },
        IS_SIDEBAR_ACTIVE() {
            return (
                this.overlayMenuActive ||
                this.staticMenuMobileActive ||
                this.overlaySubmenuActive
            )
        },
    },
    getters: {
        GET_LOCALE(state: UiStoreState): string {
            return state.locale
        },
        GET_LOCALE_FULL(state: UiStoreState): string {
            return state.locale_full
        },
    },
})

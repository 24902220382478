// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/cm'

export const CompanyManagementRouter: Array<RouteRecordRaw> = [
    {
        path: '/company-management/:pathMatch(.*)*',
        name: 'Gestione aziendale legacy',
        redirect: (to) => '/cm/' + to.params.pathMatch,
    },

    {
        path: basePath,
        name: 'Gestione aziendale',
        redirect: `${basePath}/passive-invoices`,
        component: AppLayout,
        alias: '/company-management',
        children: [
            {
                path: `${basePath}/active-invoices`,
                name: 'Fatture attive',
                meta: {
                    title: 'Fatture attive',
                    active: true,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesIndex.vue'
                    ),
            },
            {
                path: `${basePath}/active-invoices-upload`,
                name: 'Upload fatture attive',
                meta: {
                    title: 'Upload fatture attive',
                    active: true,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesUpload.vue'
                    ),
            },

            {
                path: `${basePath}/payments-schedule`,
                name: 'Scadenziario',
                meta: {
                    title: 'Scadenziario',
                    active: false,
                    excluded_categories: [21, 22],
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/schedules/CMSchedulesIndex.vue'
                    ),
            },
            {
                path: `${basePath}/takings-schedule`,
                name: 'Incassi',
                meta: {
                    title: 'Incassi',
                    active: true,
                    excluded_categories: [21, 22],
                    use_assumed_dates: true,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/schedules/CMSchedulesIndex.vue'
                    ),
            },
            {
                path: `${basePath}/payments-predictions`,
                name: 'Stime',
                meta: {
                    title: 'Stime',
                    categories: [22],
                    active_selector: true,
                    disable_toggle_estimate_cat: true,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/schedules/CMSchedulesIndex.vue'
                    ),
            },
            {
                path: `${basePath}/cash-flow`,
                name: 'Flusso di cassa',
                meta: {
                    title: 'Flusso di cassa',
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/CMCashFlow.vue'
                    ),
            },
            {
                path: `${basePath}/summary`,
                name: 'Riassunto gestione aziendale',
                meta: {
                    title: 'Riassunto gestione aziendale',
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/CMSummary.vue'
                    ),
            },
            {
                path: `${basePath}/cost-add`,
                name: 'Aggiungi scadenza',
                meta: {
                    title: 'Aggiungi scadenza',
                    active: false,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/schedules/CMScheduleAdd.vue'
                    ),
            },
            {
                path: `${basePath}/taking-add`,
                name: 'Aggiungi entrata',
                meta: {
                    title: 'Aggiungi entrata',
                    active: true,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/schedules/CMScheduleAdd.vue'
                    ),
            },
            {
                path: `${basePath}/passive-invoices`,
                name: 'Fatture passive',
                meta: {
                    title: 'Fatture passive',
                    active: false,
                    excluded_categories: [21],
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesIndex.vue'
                    ),
            },
            {
                path: `${basePath}/foreign-invoices`,
                name: 'Fatture estere',
                meta: {
                    title: 'Fatture estere',
                    active: false,
                    is_foreign: true,
                    excluded_categories: [21],
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesIndex.vue'
                    ),
            },
            {
                path: `${basePath}/passive-invoices-by-status/:direction/:verification_status`,
                name: 'Fatture per stato',
                meta: {
                    title: 'Fatture per stato',
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesByVerificationStatus.vue'
                    ),
            },
            {
                path: `${basePath}/passive-invoices-upload`,
                name: 'Upload fatture passive',
                meta: {
                    title: 'Upload fatture passive',
                    active: false,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesUpload.vue'
                    ),
            },
            {
                path: `${basePath}/invoice-add`,
                name: 'Aggiungi fattura',
                meta: {
                    title: 'Aggiungi fattura',
                    active: false,
                    is_foreign: false,
                    set_verification_end_status: true,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoiceAdd.vue'
                    ),
            },
            {
                path: `${basePath}/foreign-invoice-add`,
                name: 'Aggiungi fattura estera',
                meta: {
                    title: 'Aggiungi fattura estera',
                    active: false,
                    is_foreign: true,
                    set_verification_end_status: true,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoiceAdd.vue'
                    ),
            },
            {
                path: `${basePath}/passive-invoices-search/sdi`,
                name: 'Export fatture SDI',
                meta: {
                    title: 'Export fatture SDI',
                    sdi: true,
                    active: false,
                    excluded_document_types: ['TD16', 'TD17', 'TD18', 'TD19'],
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesSearch.vue'
                    ),
            },
            {
                path: `${basePath}/passive-invoices-search/ext`,
                name: 'Export fatture extra SDI',
                meta: {
                    title: 'Export fatture extra SDI',
                    sdi: false,
                    active: false,
                    is_foreign: false,
                    excluded_document_types: ['TD16', 'TD17', 'TD18', 'TD19'],
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesSearch.vue'
                    ),
            },
            {
                path: `${basePath}/passive-invoices-search/foreign`,
                name: 'Export fatture estere',
                meta: {
                    title: 'Export fatture estere',
                    active: false,
                    is_foreign: true,
                    excluded_document_types: ['TD16', 'TD17', 'TD18', 'TD19'],
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoicesSearch.vue'
                    ),
            },
            {
                path: `${basePath}/costs-search`,
                name: 'Ricerca scadenze',
                meta: {
                    title: 'Ricerca scadenze',
                    active: false,
                    excluded_categories: [12],
                    has_invoice: false,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/schedules/CMScheduleSearch.vue'
                    ),
            },
            {
                path: `${basePath}/takings-search`,
                name: 'Ricerca incassi',
                meta: {
                    title: 'Ricerca incassi',
                    active: true,
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/schedules/CMScheduleSearch.vue'
                    ),
            },

            // Impostazioni
            {
                path: `${basePath}/settings`,
                name: 'Impostazioni gestione aziendale',
                meta: {
                    title: 'Impostazioni gestione aziendale',
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/CMSettings.vue'
                    ),
                children: [
                    {
                        path: 'categories',
                        name: 'Gestisci categorie fatture',
                        meta: {
                            title: 'Gestisci categorie fatture',
                        },
                        component: () =>
                            import(
                                '@/modules/company-management/components/CMCategories.vue'
                            ),
                    },
                    {
                        path: 'payment-accounts',
                        name: 'Gestisci conti di pagamento',
                        meta: {
                            title: 'Gestisci conti di pagamento',
                        },
                        component: () =>
                            import(
                                '@/modules/company-management/components/PaymentAccounts.vue'
                            ),
                    },
                    {
                        path: 'invoice-statuses',
                        name: 'Gestisci stati fatture',
                        meta: {
                            title: 'Gestisci stati fatture',
                        },
                        component: () =>
                            import(
                                '@/modules/company-management/components/CMInvoiceStatuses.vue'
                            ),
                    },
                ],
            },

            {
                path: `${basePath}/invoice/:id`,
                name: 'Fattura',
                meta: {
                    // title: '◌',
                },
                component: () =>
                    import(
                        '@/modules/company-management/components/invoices/CMInvoice.vue'
                    ),
            },
        ],
    },
]

<script setup>
import ScrollTop from 'primevue/scrolltop'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import AppTopbar from './AppTopbar.vue'

import { useUiStore } from '@/modules/primevue/stores/ui-store'
import AppProfileMenu from './AppProfileMenu.vue'

const outsideClickListener = ref(null)
const topbarRef = ref(null)
const uiStore = useUiStore()

watch(uiStore.IS_SIDEBAR_ACTIVE, (newVal) => {
    if (newVal) {
        bindOutsideClickListener()
    } else {
        unbindOutsideClickListener()
    }
})

onBeforeUnmount(() => {
    unbindOutsideClickListener()
})

const containerClass = computed(() => {
    return [
        {
            'layout-light': uiStore.colorScheme === 'light',
            'layout-dark': uiStore.colorScheme === 'dark',
            'layout-reveal': true,
            'layout-static-inactive': uiStore.staticMenuDesktopInactive,
            'layout-overlay-active': uiStore.overlayMenuActive,
            'layout-mobile-active': uiStore.staticMenuMobileActive,
            'layout-sidebar-active': uiStore.sidebarActive,
            'layout-sidebar-anchored': uiStore.anchored,
        },
    ]
})

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                uiStore.overlayMenuActive = false
                uiStore.staticMenuMobileActive = false
                uiStore.overlaySubmenuActive = false
                uiStore.menuHoverActive = false
            }
        }
        document.addEventListener('click', outsideClickListener.value)
    }
}
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener)
        outsideClickListener.value = null
    }
}
const isOutsideClicked = (event) => {
    if (!topbarRef.value) return

    const sidebarEl = topbarRef?.value.$el.querySelector('.layout-sidebar')
    const topbarEl = topbarRef?.value.$el.querySelector(
        '.topbar-start > button',
    )

    return !(
        sidebarEl.isSameNode(event.target) ||
        sidebarEl.contains(event.target) ||
        topbarEl.isSameNode(event.target) ||
        topbarEl.contains(event.target)
    )
}
</script>
<template>
    <div class="layout-container" :class="containerClass">
        <div class="layout-content-wrapper">
            <AppTopbar ref="topbarRef" />

            <div class="layout-content">
                <router-view></router-view>
                <ScrollTop />
            </div>

            <div class="layout-mask"></div>
        </div>
        <AppProfileMenu />
    </div>
</template>

<style lang="scss"></style>

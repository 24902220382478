// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/usr'

export const UsersRouter: Array<RouteRecordRaw> = [
    ...(import.meta.env.VITE_LOGIN_PROVIDER === 'cognito' &&
    import.meta.env.PROD
        ? []
        : [
              {
                  path: '/login',
                  name: 'Login',
                  component: () =>
                      import('@/modules/user/components/AppLogin.vue'),
                  meta: {
                      title: 'Login',
                      noAuth: true,
                  },
              },
              {
                  path: '/complete-user-profile',
                  name: 'Cambia password',
                  component: () =>
                      import(
                          '@/modules/user/components/AppCompleteUserProfile.vue'
                      ),
                  meta: {
                      title: 'Completa dati utente',
                      noAuth: true,
                  },
              },
          ]),
    {
        path: basePath,
        name: 'Utenti e gruppi',
        redirect: `${basePath}/index`,
        component: AppLayout,
        children: [
            {
                path: `${basePath}/index`,
                name: 'Elenco utenti',
                meta: {
                    title: 'Elenco utenti',
                },
                component: () =>
                    import('@/modules/user/components/UsersIndex.vue'),
            },
            {
                path: `${basePath}/groups`,
                name: 'Gestisci gruppi',
                meta: {
                    title: 'Gestisci gruppi',
                },
                component: () =>
                    import('@/modules/user/components/UserGroupsIndex.vue'),
            },
            {
                path: `${basePath}/group/:id/permissions`,
                name: 'Gestisci permessi gruppo',
                meta: {
                    title: 'Gestisci permessi gruppo',
                },
                component: () =>
                    import('@/modules/user/components/UserGroupPerms.vue'),
            },
        ],
    },
]

// Components
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/wh'

export default {
    path: `${basePath}/settings`,
    name: 'Impostazioni magazzino',
    meta: {
        title: 'Impostazioni magazzino',
    },
    component: () =>
        import('@/modules/warehouse/components/WarehouseSettings.vue'),
    children: [
        {
            path: 'brands/:isAdd?',
            name: 'Gestisci marche',
            meta: {
                title: 'Gestisci marche',
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/ba0d25c1-8f9e-4fbe-9696-9376b5df3d1d',
                        label: '2.14 - Aggiungere una marca di produttore',
                    },
                ],
            },
            component: () =>
                import('@/modules/warehouse/components/Brands.vue'),
        },
        {
            path: 'categories/:isAdd?',
            name: 'Gestisci categorie',
            meta: {
                title: 'Gestisci categorie',
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/dab70cf9-2ca9-47b7-8db3-aee7a6094134',
                        label: '2.10 - Aggiungere una categoria di materiale a magazzino',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/9badae74-c58e-4888-846e-520102e95246',
                        label: '2.11 - Eliminare una categoria di materiale a magazzino',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/72029289-304a-4e9d-b5b7-b86e704ecc0b',
                        label: '2.12 - Aggiungere una sottocategoria di materiale a magazzino',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/e1ac2fe9-a656-43e7-9074-93e0500b69cd',
                        label: '2.13 - Eliminare una sottocategoria di materiale a magazzino',
                    },
                ],
            },
            component: () =>
                import('@/modules/warehouse/components/ProductCategories.vue'),
        },
        {
            path: 'categories-order',
            name: 'Riordina categorie',
            meta: {
                title: 'Riordina categorie',
            },
            component: () =>
                import(
                    '@/modules/warehouse/components/ProductCategoriesOrder.vue'
                ),
        },
        {
            path: 'import',
            name: 'Documenti importabili magazzino',
            meta: {
                title: 'Documenti importabili magazzino',
            },
            component: () =>
                import(
                    '@/modules/warehouse/components/WarehouseDataImport.vue'
                ),
        },
    ],
} as RouteRecordRaw

import type { Technician } from '@/modules/technician/models'

const BASE_URL = '/tc'

export function toTechnicianLink(
    value: Technician | number,
    tab?: string,
): string {
    let calcUrl
    if (typeof value === 'number') calcUrl = `${BASE_URL}/${value}`
    else calcUrl = `${BASE_URL}/${value.technician_id}`

    if (tab) {
        calcUrl += `/${tab}`
    }
    return calcUrl
}

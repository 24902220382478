import { booleanValue } from '@/modules/sls/helpers/sanitize-helpers'
import { defineStore } from 'pinia'
import type { MenuItem } from 'primevue/menuitem'
import { getAllMenuItems, state } from './menu-store-state'

const itemHasVisibleChildren = (items: MenuItem[]) =>
    items.reduce((acc, child): boolean => {
        let currentChildIsVisible = !!child.visible

        booleanValue(booleanValue(import.meta.env.VITE_DEBUG_MENU)) &&
            console.debug(
                'itemHasVisibleChildren currentChildIsVisible for ',
                child.label,
                currentChildIsVisible,
            )

        if (child.items && child.items.length > 0) {
            booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
                console.debug(
                    'itemHasVisibleChildren recursive for ',
                    child.label,
                )
            child.visible = itemHasVisibleChildren(child.items)
            booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
                console.debug(
                    '> itemHasVisibleChildren recursive for ',
                    child.label,
                    currentChildIsVisible,
                )
            // Se il menu ha figli non influenza l'accumulatore
            return acc
        } else if (typeof child.visible === 'function') {
            currentChildIsVisible = child.visible()
            booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
                console.debug(
                    'itemHasVisibleChildren fn for ',
                    child.label,
                    'result',
                    currentChildIsVisible,
                )
        } else if (typeof child.visible === 'undefined') {
            booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
                console.debug(
                    'itemHasVisibleChildren undefined for ',
                    child.label,
                )
            currentChildIsVisible = true
        }

        booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
            console.debug(
                'itemHasVisibleChildren result for ',
                child.label,
                acc || currentChildIsVisible,
                'current',
                currentChildIsVisible,
                'acc',
                acc,
            )

        return acc || currentChildIsVisible
    }, false)

const normalizeVisibleAttribute = (menuItem: MenuItem): MenuItem => {
    if (typeof menuItem.visible !== 'undefined') return menuItem

    if (!menuItem.items || menuItem.items.length === 0) {
        booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
            console.debug('normalizeVisibleAttribute c2', menuItem.label)
        menuItem.visible = true
        return menuItem
    }

    booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
        console.debug('normalizeVisibleAttribute c3', menuItem.label)
    menuItem.visible = itemHasVisibleChildren(menuItem.items)

    return menuItem
}

export const useMenuStore = defineStore('menu', {
    state,
    actions: {
        UPDATE_MENU_VISIBLE_ITEMS() {
            this.menu = getAllMenuItems().map(normalizeVisibleAttribute)
        },
    },
    getters: {},
})

// Declare state
import type { GetSchedules } from '@/modules/company-management/api/request-types'
import type {
    CMSchedule,
    CMScheduleTotals,
} from '@/modules/company-management/models'

export type InvoiceScheduleStoreState = {
    schedules: {
        filtered: { [key: string]: CMSchedule[] }
        byId: { [key: number]: CMSchedule }
    }
    scheduleTotals: {
        [key: number]: {
            by_date: CMScheduleTotals[]
            by_month: CMScheduleTotals[]
        }
    }
    lastFilters: {
        costsIndex?: GetSchedules
    }
}

// Create initial state
export const state = (): InvoiceScheduleStoreState => ({
    schedules: {
        filtered: {},
        byId: {},
    },
    scheduleTotals: {},
    lastFilters: {},
})

import config from '@/config'
import { useInvoiceStore } from '@/modules/company-management/store/invoice-store'
import { useMenuStore } from '@/modules/primevue/stores/menu-store'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { SLSApiRequestToIdx } from '@/modules/sls/helpers/index-helpers'
import { booleanValue } from '@/modules/sls/helpers/sanitize-helpers'
import type { SLSApiRequest } from '@/modules/sls/models'
import { requestSelectors } from '@/modules/user/api/request-selectors'
import * as requestTypes from '@/modules/user/api/request-types'
import getCognitoUserPool, {
    cognitoAuthenticationCallback,
    getStorage,
    setAuthorizationHeader,
    startTokenRefreshHandler,
} from '@/modules/user/helpers/get-cognito-user-pool'
import type { CurrentUser } from '@/modules/user/models'
import router, { getLastRequestedRoute, setLastRequestedRoute } from '@/router'
import { axiosRequest } from '@/store/action-helpers'
import * as Sentry from '@sentry/browser'
import {
    AuthenticationDetails,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserSession,
} from 'amazon-cognito-identity-js'
import axios from 'axios'
import { defineStore } from 'pinia'
import { state } from './current-user-store-state'

export const useCurrentUserStore = defineStore('currentUser', {
    state,

    getters: {
        IS_AUTHENTICATED(): boolean {
            switch (import.meta.env.VITE_LOGIN_PROVIDER) {
                case 'cognito':
                    return this.cognitoSession !== null
                default:
                    return !!this.currentUser?.user_id
            }
        },
        HAS_PERMISSION() {
            return (
                permission: string | SLSApiRequest,
                checkEconomicData = false,
            ): boolean => {
                const key =
                    typeof permission === 'string'
                        ? permission
                        : SLSApiRequestToIdx(permission)

                return (
                    this.currentUser !== null &&
                    !!this.currentUser.perms[key] &&
                    (!checkEconomicData ||
                        !!this.currentUser.perms[key].economic_data_allowed)
                )
            }
        },

        HAS_NOT_PERMISSION() {
            return (
                permission: string | SLSApiRequest,
                checkEconomicData = false,
            ): boolean => !this.HAS_PERMISSION(permission, checkEconomicData)
        },

        HAS_PERMISSIONS() {
            return (
                permissions: (string | SLSApiRequest)[],
                checkEconomicData = false,
            ): boolean =>
                permissions.reduce(
                    (acc: boolean, item: string | SLSApiRequest) =>
                        acc && this.HAS_PERMISSION(item, checkEconomicData),
                    true,
                )
        },

        HAS_ONE_PERMISSION() {
            return (
                permissions: (string | SLSApiRequest)[],
                checkEconomicData = false,
            ): boolean =>
                permissions.reduce(
                    (acc: boolean, item: string | SLSApiRequest) =>
                        acc || this.HAS_PERMISSION(item, checkEconomicData),
                    false,
                )
        },

        HAS_NOT_PERMISSIONS() {
            return (
                permissions: (string | SLSApiRequest)[],
                checkEconomicData = false,
            ): boolean => !this.HAS_PERMISSIONS(permissions, checkEconomicData)
        },
    },

    actions: {
        async AS_USER_ENABLE(asUserId: number) {
            axios.defaults.headers.common['As-User-Id'] = asUserId

            await this.FETCH_USER_DATA()

            this.asUserActive = true
        },
        async AS_USER_DISABLE() {
            delete axios.defaults.headers.common['As-User-Id']

            await this.FETCH_USER_DATA()

            this.asUserActive = false
        },
        GET_USER_INSTALLATIONS() {
            let userInstallations: string[] = []
            switch (import.meta.env.VITE_LOGIN_PROVIDER) {
                case 'cognito':
                    if (this.cognitoSession) {
                        userInstallations =
                            this.cognitoSession?.getIdToken().decodePayload()[
                                'cognito:groups'
                            ] ?? []
                    }
            }

            this.userInstallations = userInstallations
        },
        async GET_COGNITO_SESSION() {
            const currentUser = getCognitoUserPool().getCurrentUser()
            if (currentUser) {
                document.cookie =
                    'PHPSESSID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'

                return new Promise<void>((resolve, reject) => {
                    currentUser.getSession(
                        (error: null, session: CognitoUserSession | null) => {
                            if (error) {
                                this.cognitoSession = null
                                reject(error)
                            } else {
                                this.cognitoSession = session
                                this.cognitoUser = currentUser

                                setAuthorizationHeader()
                                startTokenRefreshHandler()

                                resolve()
                            }
                        },
                    )
                })
            } else {
                this.cognitoSession = null
                this.currentUser = null
            }
        },
        async COMPLETE_USER_PROFILE(data: any) {
            console.debug('COMPLETE_USER_PROFILE', data)

            const password = data.pwd
            delete data.pwd
            delete data.pwd_confirm
            delete data.email
            delete data.email_verified

            return new Promise<void>((resolve, reject) => {
                this.cognitoUser?.completeNewPasswordChallenge(
                    password,
                    data,
                    cognitoAuthenticationCallback(resolve, reject),
                )
            })
        },
        async REFRESH_COGNITO_SESSION() {
            console.debug('REFRESH_COGNITO_SESSION')

            return new Promise<void>((resolve, reject) => {
                console.debug(this.cognitoUser)
                if (this.cognitoUser) {
                    console.debug(
                        'REFRESH_COGNITO_SESSION cognitoUser',
                        this.cognitoUser,
                    )

                    try {
                        this.cognitoUser.refreshSession(
                            this.cognitoSession?.getRefreshToken() as CognitoRefreshToken,
                            (err, data) => {
                                if (err) {
                                    this.LOGOUT()
                                    reject(err)
                                } else {
                                    this.cognitoUser?.setSignInUserSession(data)

                                    this.cognitoSession = data

                                    setAuthorizationHeader()
                                    startTokenRefreshHandler()

                                    try {
                                        axios.post(
                                            import.meta.env
                                                .VITE_COGNITO_UPDATE_TOKENS_URL,
                                            {
                                                accessToken: data.accessToken,
                                                idToken: data.idToken,
                                                refreshToken: data.refreshToken,
                                            },
                                        )
                                    } catch (e) {
                                        console.debug(
                                            'Impossibile aggiornare FOH con i nuovi token',
                                            e,
                                        )
                                    }

                                    resolve()
                                }
                            },
                        )
                    } catch (e) {
                        window.location.href =
                            import.meta.env.VITE_COGNITO_LOGIN_URL
                    }
                }
            })
        },

        async LOGIN(payload: requestTypes.LoginData) {
            const uiStore = useUiStore()

            switch (import.meta.env.VITE_LOGIN_PROVIDER) {
                case 'cognito':
                    this.cognitoUser = new CognitoUser({
                        Username: payload.user,
                        Pool: getCognitoUserPool(),
                        Storage: getStorage(),
                    })
                    try {
                        await new Promise<void>((resolve, reject) => {
                            this.cognitoUser?.authenticateUser(
                                new AuthenticationDetails({
                                    Username: payload.user,
                                    Password: payload.pwd,
                                }),
                                cognitoAuthenticationCallback(resolve, reject),
                            )
                        })

                        await this.FETCH_USER_DATA()

                        await router.replace(
                            getLastRequestedRoute() ||
                                this.currentUser?.config?.base_path ||
                                import.meta.env.VITE_DEFAULT_HOME_PATH,
                        )
                    } catch (e) {
                        console.error(e)

                        uiStore.ADD_TOAST({
                            severity: 'warn',
                            detail: 'Credenziali errate',
                            life: 3000,
                        })
                    }
                    break

                default:
                    return axiosRequest(
                        async () => {
                            const response = await axios.post(
                                config().ENDPOINT,
                                {
                                    ...requestSelectors.LOGIN,
                                    ...payload,
                                },
                            )

                            this.currentUser = response.data as CurrentUser
                        },
                        async () => {
                            uiStore.CLEAR_TOASTS()

                            await router.replace(
                                getLastRequestedRoute() ||
                                    this.currentUser?.config?.base_path ||
                                    import.meta.env.VITE_DEFAULT_HOME_PATH,
                            )
                        },
                        () => {
                            uiStore.ADD_TOAST({
                                severity: 'warn',
                                detail: 'Credenziali errate',
                                life: 3000,
                            })
                        },
                    )
            }
        },
        async LOGOUT() {
            const uiStore = useUiStore()

            switch (import.meta.env.VITE_LOGIN_PROVIDER) {
                case 'cognito':
                    this.cognitoUser = null
                    this.cognitoSession = null
                    this.currentUser = null

                    uiStore.CLEAR_TOASTS()

                    return new Promise<void>((resolve, reject) => {
                        getCognitoUserPool()
                            .getCurrentUser()
                            ?.globalSignOut({
                                onSuccess: () => {
                                    window.location.href =
                                        import.meta.env.VITE_COGNITO_LOGIN_URL

                                    resolve()
                                },
                                onFailure: () => {
                                    window.location.href =
                                        import.meta.env.VITE_COGNITO_LOGIN_URL

                                    reject()
                                },
                            })
                    })

                default:
                    await axiosRequest(
                        async () =>
                            axios.post(config().ENDPOINT, {
                                ...requestSelectors.LOGOUT,
                            }),
                        async () => {
                            uiStore.CLEAR_TOASTS()

                            this.currentUser = null

                            await router.push('/login')
                        },
                    )
            }
        },
        async FETCH_USER_DATA() {
            try {
                const response = await axios.get(config().ENDPOINT, {
                    params: requestSelectors.GET,
                })

                this.currentUser = response.data as CurrentUser

                // Controllo se ho permessi per vedere le sezioni di fatturazione del menu degli stati, se sì le carico
                if (this.HAS_PERMISSION('cm,get_invoice')) {
                    const invoiceStore = useInvoiceStore()
                    await invoiceStore.GET_INVOICE_STATUSES()
                }

                // Aggiorno la visibilità degli item del menu sulla base dei permessi appena ricevuti
                useMenuStore().UPDATE_MENU_VISIBLE_ITEMS()

                if (booleanValue(import.meta.env.VITE_SENTRY_ENABLED)) {
                    Sentry.setUser({
                        id:
                            this.cognitoUser?.getUsername() ??
                            this.currentUser.email,
                        username: this.currentUser.email,
                        name: this.currentUser.name,
                        surname: this.currentUser.surname,
                        email: this.currentUser.email,
                    })
                }

                if (
                    !this.currentUser ||
                    this.currentUser.user_id !== response.data.user_id
                ) {
                    // Still the same user
                    await router.push({
                        path: response.data.config.base_path,
                    })
                } else if (router.currentRoute.value.name === 'Login') {
                    // Current route is login, go to valid route
                    await router.replace(
                        getLastRequestedRoute() ||
                            this.currentUser.config.base_path,
                    )
                }
            } catch (e: any) {
                // Pulisco utente corrente
                this.currentUser = null
                this.cognitoSession = null
                this.cognitoUser = null

                // Errore non identificato
                const responseStatus = e?.response?.status

                // Errore non identificato
                if (!responseStatus) throw e

                // Se non autenticato ma in una rotta, navigo al login salvando la rotta corrente
                if (router.currentRoute.value.name === 'Login') {
                    return
                }
                setLastRequestedRoute(router.currentRoute.value)

                if (
                    import.meta.env.VITE_LOGIN_PROVIDER === 'cognito' &&
                    import.meta.env.PROD
                ) {
                    const authUrl = new URL(
                        import.meta.env.VITE_COGNITO_LOGIN_URL,
                    )

                    authUrl.searchParams.append(
                        'redirect_uri',
                        window.location.origin + window.location.pathname,
                    )

                    console.debug(authUrl, authUrl.toString())

                    window.location.href = authUrl.toString()
                    return
                } else {
                    await router.push({ name: 'Login' })
                }
            }
        },
    },
})

// Components
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/tc'

export default {
    path: `${basePath}/settings`,
    name: 'Impostazioni tecnici',
    meta: {
        title: 'Impostazioni tecnici',
    },
    component: () =>
        import('@/modules/technician/components/TechnicianSettings.vue'),
    children: [
        {
            path: 'roles',
            name: 'Gestione competenze',
            meta: {
                title: 'Gestione competenze',
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/6f98f429-cbe2-4937-b756-921aa26a74c7',
                        label: '3.5 - Aggiungere un settore di competenza',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/6835e2b7-94f2-4c1b-a698-171ccf17b32d',
                        label: '3.6 - Aggiungi una competenza',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/b05f6e12-616f-4281-bbf5-af670a854f62',
                        label: '3.7 - Spostare una competenza',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/9fe0bbe2-077b-4920-83b0-19b6561f9fb4',
                        label: '3.8 - Eliminare una competenza e un settore di competenza',
                    },
                ],
            },
            component: () =>
                import('@/modules/technician/components/TechnicianRoles.vue'),
        },
        {
            path: 'import',
            name: 'Documenti importabili tecnici',
            meta: {
                title: 'Documenti importabili tecnici',
            },
            component: () =>
                import(
                    '@/modules/technician/components/TechnicianDataImport.vue'
                ),
        },
    ],
} as RouteRecordRaw

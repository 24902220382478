// Components
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/ab'

export default {
    path: `${basePath}/settings`,
    name: 'Impostazioni rubrica',
    meta: {
        title: 'Impostazioni rubrica',
    },
    component: () =>
        import('@/modules/address-book/components/AddressBookSettings.vue'),
    children: [
        {
            path: 'import',
            name: 'Documenti importabili rubrica',
            meta: {
                title: 'Documenti importabili rubrica',
            },
            component: () =>
                import(
                    '@/modules/address-book/components/AddressBookDataImport.vue'
                ),
        },
    ],
} as RouteRecordRaw

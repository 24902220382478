import type { RouteRecordRaw } from 'vue-router'

const basePath = '/pr'

export default {
    path: `${basePath}/stats`,
    name: 'Statistiche produzioni',
    meta: {
        title: 'Statistiche produzioni',
    },
    component: () =>
        import('@/modules/production/components/ProductionStats.vue'),
    children: [
        {
            path: `estimate`,
            name: 'Statistiche preventivi cliente',
            meta: {
                title: 'Statistiche preventivi cliente',
            },
            component: () =>
                import(
                    '@/modules/production/components/ProductionEstimatesStats.vue'
                ),
        },
        {
            path: `ext-suppliers`,
            name: 'Statistiche fornitori',
            meta: {
                title: 'Statistiche fornitori',
            },
            component: () =>
                import(
                    '@/modules/production/components/ExternalSuppliersStats.vue'
                ),
        },
    ],
} as RouteRecordRaw

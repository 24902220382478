// Declare state
import type { GetInvoices } from '@/modules/company-management/api/request-types'
import type {
    CMInvoice,
    CMInvoiceCategory,
    CMInvoiceStatus,
    CompanyManagementInvoiceType,
} from '@/modules/company-management/models'

export type InvoiceStoreState = {
    invoices: {
        filtered: { [key: string]: CMInvoice[] }
        byId: { [key: number]: CMInvoice }
    }
    lastFilters: {
        activeInvoicesIndex?: GetInvoices
        passiveInvoicesIndex?: GetInvoices
    }
    allInvoiceCategories: CMInvoiceCategory[]
    activeInvoiceCategories: CMInvoiceCategory[]
    passiveInvoiceCategories: CMInvoiceCategory[]
    categoriesById: { [key: number]: CMInvoiceCategory }
    invoiceTypes: CompanyManagementInvoiceType[]
    invoiceTypesById: {
        [key: string]: string
    }
    invoiceVerificationStatus: CMInvoiceStatus[]
    invoiceVerificationStatusWithMetadata: CMInvoiceStatus[]
    invoiceVerificationStatusById: { [key: number]: CMInvoiceStatus }
}

// Create initial state
export const state = (): InvoiceStoreState => {
    const base: InvoiceStoreState = {
        invoices: {
            filtered: {},
            byId: {},
        },
        lastFilters: {},
        allInvoiceCategories: [],
        activeInvoiceCategories: [],
        passiveInvoiceCategories: [],
        categoriesById: {},
        invoiceTypes: [
            { invoice_type_id: 'TD00', name: 'ricevuta' },
            { invoice_type_id: 'TD01', name: 'fattura' },
            { invoice_type_id: 'TD02', name: 'acconto/anticipo su fattura' },
            { invoice_type_id: 'TD03', name: 'acconto/anticipo su parcella' },
            { invoice_type_id: 'TD04', name: 'nota di credito' },
            { invoice_type_id: 'TD05', name: 'nota di debito' },
            { invoice_type_id: 'TD06', name: 'parcella' },
            {
                invoice_type_id: 'TD16',
                name: 'integrazione fattura reverse charge interno',
            },
            {
                invoice_type_id: 'TD17',
                name: "integrazione/autofattura per acquisto servizi dall'estero",
            },
            {
                invoice_type_id: 'TD18',
                name: 'integrazione per acquisto di beni intracomunitari',
            },
            {
                invoice_type_id: 'TD19',
                name: 'integrazione/autofattura per acquisto di beni ex art.17 c.2 DPR 633/72',
            },
            {
                invoice_type_id: 'TD20',
                name: 'autofattura per regolarizzazione e integrazione delle fatture',
            },
            { invoice_type_id: 'TD21', name: 'autofattura per splafonamento' },
            {
                invoice_type_id: 'TD22',
                name: 'estrazione beni da Deposito IVA',
            },
            {
                invoice_type_id: 'TD23',
                name: "estrazione beni da Deposito IVA con versamento dell'IVA",
            },
            {
                invoice_type_id: 'TD24',
                name: "fattura differita di cui all'art. 21, comma 4, terzo periodo lett. a) DPR 633/72",
            },
            {
                invoice_type_id: 'TD25',
                name: "fattura differita di cui all'art. 21, comma 4, terzo periodo lett. b) DPR 633/72",
            },
            {
                invoice_type_id: 'TD26',
                name: 'cessione di beni ammortizzabili e per passaggi interni (ex art.36 DPR 633/72)',
            },
            {
                invoice_type_id: 'TD27',
                name: 'fattura per autoconsumo o per cessioni gratuite senza rivalsa',
            },
        ],
        invoiceVerificationStatus: [],
        invoiceVerificationStatusWithMetadata: [],
        invoiceVerificationStatusById: {},
        invoiceTypesById: {},
    }

    base.invoiceTypes.forEach((value) => {
        base.invoiceTypesById[value.invoice_type_id] = value.name
    })

    return base
}

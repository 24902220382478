// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'
import type { RouteRecordRaw } from 'vue-router'
import addressBookSettings from './_settings'

const basePath = '/ab'

export const AddressBookRouter: Array<RouteRecordRaw> = [
    {
        path: basePath,
        name: 'Rubrica',
        redirect: `${basePath}/index`,
        component: AppLayout,
        meta: {
            nameKey: 'SLS.address_book',
        },
        children: [
            {
                path: `${basePath}/index/:addType?`,
                name: 'Indice',
                meta: {
                    title: 'Elenco schede rubrica',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/dc6743b9-99dd-4d3f-94aa-72431b0ace7f',
                            label: '6.1 - Ricerca voci in rubrica',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/ee58fba3-4851-4134-bfce-2039fe5a1541',
                            label: '6.2 - Aggiungere una nuova scheda di privato/professionista in rubrica',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/7daf4bf1-90e2-4818-847f-b6fdadffa637',
                            label: '6.3 - Aggiungere un nuovo fornitore',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/0ae9417e-a4c3-4c3a-bc5b-9916138092fc',
                            label: '6.4 - Aggiungere un nuovo cliente',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/8b809c57-0471-4372-9809-a2461bf9fa66',
                            label: '6.5 - Aggiungere un contatto e un riferimento ad un contatto',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/24f0764e-4f2f-4a40-aa9a-c6d9233e8621',
                            label: '6.6 - Aggiungere un indirizzo',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/address-book/components/AddressBookIndex.vue'
                    ),
            },
            {
                path: `${basePath}/:id`,
                name: 'Scheda rubrica',
                meta: {
                    title: 'Scheda rubrica',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/7daf4bf1-90e2-4818-847f-b6fdadffa637',
                            label: '6.3 - Aggiungere un nuovo fornitore',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/0ae9417e-a4c3-4c3a-bc5b-9916138092fc',
                            label: '6.4 - Aggiungere un nuovo cliente',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/8b809c57-0471-4372-9809-a2461bf9fa66',
                            label: '6.5 - Aggiungere un contatto e un riferimento ad un contatto',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/24f0764e-4f2f-4a40-aa9a-c6d9233e8621',
                            label: '6.6 - Aggiungere un indirizzo',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/address-book/components/AddressBookSheet.vue'
                    ),
            },
            {
                path: `${basePath}/suppliers`,
                name: 'Scheda rubrica fornitori',
                meta: {
                    title: 'Scheda rubrica fornitori',
                },
                component: () =>
                    import(
                        '@/modules/address-book/components/AddressBookSuppliers.vue'
                    ),
            },
            {
                path: `${basePath}/customers`,
                name: 'Scheda rubrica clienti',
                meta: {
                    title: 'Scheda rubrica clienti',
                },
                component: () =>
                    import(
                        '@/modules/address-book/components/AddressBookCustomers.vue'
                    ),
            },
            {
                path: `${basePath}/:id/general`,
                name: 'Scheda rubrica generale',
                redirect: `${basePath}`,
            },
            {
                path: `${basePath}/:id/supplier`,
                name: 'Scheda rubrica fornitore',
                redirect: `${basePath}`,
            },
            {
                path: `${basePath}/:id/customer`,
                name: 'Scheda rubrica cliente',
                redirect: `${basePath}`,
            },
            {
                path: `${basePath}/:id/addresses`,
                name: 'Scheda rubrica indirizzi',
                redirect: `${basePath}`,
            },
            {
                path: `${basePath}/:id/contacts`,
                name: 'Scheda rubrica contatti',
                redirect: `${basePath}`,
            },

            // Impostazioni
            addressBookSettings,
        ],
    },
]

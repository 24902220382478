import addressBookEn from '@/modules/address-book/locales/en-US.json'
import addressBookIt from '@/modules/address-book/locales/it-IT.json'
import companyManagementEn from '@/modules/company-management/locales/en-US.json'
import companyManagementIt from '@/modules/company-management/locales/it-IT.json'
import companyVehiclesEn from '@/modules/company-vehicle/locales/en-US.json'
import companyVehiclesIt from '@/modules/company-vehicle/locales/it-IT.json'
import devEn from '@/modules/dev/locales/en-US.json'
import devIt from '@/modules/dev/locales/it-IT.json'
import messagingEn from '@/modules/messaging/locales/en-US.json'
import messagingIt from '@/modules/messaging/locales/it-IT.json'
import uiEn from '@/modules/primevue/locales/en-US.json'
import uiIt from '@/modules/primevue/locales/it-IT.json'
import productionsEn from '@/modules/production/locales/en-US.json'
import productionsIt from '@/modules/production/locales/it-IT.json'
import saleEn from '@/modules/sales/locales/en-US.json'
import saleIt from '@/modules/sales/locales/it-IT.json'
import slsEn from '@/modules/sls/locales/en-US.json'
import slsIt from '@/modules/sls/locales/it-IT.json'
import technicianEn from '@/modules/technician/locales/en-US.json'
import technicianIt from '@/modules/technician/locales/it-IT.json'
import usersEn from '@/modules/user/locales/en-US.json'
import usersIt from '@/modules/user/locales/it-IT.json'
import warehouseEn from '@/modules/warehouse/locales/en-US.json'
import warehouseIt from '@/modules/warehouse/locales/it-IT.json'
import { createI18n, type ComposerTranslation } from 'vue-i18n'

export type MessageSchema = typeof itIT

export const itIT = {
    ui: uiIt.it,
    ...addressBookIt,
    ...companyManagementIt,
    ...companyVehiclesIt,
    ...devIt,
    ...messagingIt,
    ...productionsIt,
    ...slsIt,
    ...saleIt,
    ...technicianIt,
    ...usersIt,
    ...warehouseIt,
}
export const enUS = {
    ui: uiEn.en,
    ...addressBookEn,
    ...companyManagementEn,
    ...companyVehiclesEn,
    ...devEn,
    ...messagingEn,
    ...productionsEn,
    ...slsEn,
    ...saleEn,
    ...technicianEn,
    ...usersEn,
    ...warehouseEn,
}

const i18nPlugin = createI18n<[MessageSchema], 'it-IT' | 'en-US'>({
    allowComposition: false,
    locale: 'it-IT',
    fallbackLocale: 'it-IT',
    legacy: true, // se false abilita composition API, ma non posso usare t nello store
    messages: {
        'it-IT': itIT,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'en-US': enUS,
    },

    fallbackWarn: !import.meta.env.PROD,
    missingWarn: !import.meta.env.PROD,
})

export default i18nPlugin

export const t = (i18nPlugin.global as any).t as ComposerTranslation

// Declare state
import type { CurrentUser } from '@/modules/user/models'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'

export type CurrentUserStoreState = {
    asUserActive: boolean
    currentUser: CurrentUser | null
    cognitoUser: CognitoUser | null
    cognitoSession: CognitoUserSession | null
    cognitoStatus: any
    userInstallations: string[]
}

// Create initial state
export const state = (): CurrentUserStoreState => ({
    asUserActive: false,
    currentUser: null,
    cognitoUser: null,
    cognitoSession: null,
    cognitoStatus: null,
    userInstallations: [],
})

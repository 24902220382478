// Components
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/tc'

export default {
    path: `${basePath}/stats`,
    name: 'Statistiche tecnici',
    meta: {
        title: 'Statistiche tecnici',
    },
    component: () =>
        import('@/modules/technician/components/TechnicianStats.vue'),
    children: [
        {
            path: 'reservations-by-technician',
            name: 'Statistiche prenotazioni tecnici per tecnico',
            meta: {
                title: 'Statistiche prenotazioni tecnici per tecnico',
                group_stat_by: 'technician',
            },
            component: () =>
                import(
                    '@/modules/technician/components/TechnicianReservationsStats.vue'
                ),
        },
        {
            path: 'reservations-by-month',
            name: 'Statistiche prenotazioni tecnici per mese',
            meta: {
                title: 'Statistiche prenotazioni tecnici per mese',
                group_stat_by: 'month',
            },
            component: () =>
                import(
                    '@/modules/technician/components/TechnicianReservationsStats.vue'
                ),
        },
    ],
} as RouteRecordRaw

import type { SLSApiRequest } from '@/modules/sls/models'

export const requestSelectors = {
    ADD_INVOICE_MANUAL: {
        sec: 'cm',
        req: 'add_invoice_manual',
    },
    GET_INVOICES: { sec: 'cm', req: 'get_invoices' },
    GET_INVOICES_ZIP: { sec: 'cm', req: 'get_invoices_zip' },
    GET_INVOICE: { sec: 'cm', req: 'get_invoice' },
    DEL_INVOICE: { sec: 'cm', req: 'del_invoice' },
    GET_INVOICE_ATTACHMENTS: {
        sec: 'cm',
        req: 'get_invoice_attachments',
    },
    DEL_INVOICE_ATTACHMENTS: {
        sec: 'cm',
        req: 'del_invoice_attachments',
    },
    // GET_INVOICE_SCHEDULES: { sec: 'cm', req: 'get_invoice_schedules' },
    UPDATE_INVOICE: { sec: 'cm', req: 'update_invoice' },
    PASSIVE_INVOICE_XML_STYLED_PDF: {
        sec: 'cm',
        req: 'passive_invoice_xml_styled_pdf',
    },
    AUTO_SET_SUPPLIER: { sec: 'cm', req: 'auto_set_supplier' },

    GET_SCHEDULES: { sec: 'cm', req: 'get_schedules' },
    GET_SCHEDULES_ZIP: { sec: 'cm', req: 'get_schedules_zip' },
    ADD_SCHEDULE: { sec: 'cm', req: 'add_schedule' },
    UPDATE_SCHEDULE: { sec: 'cm', req: 'update_schedule' },
    DEL_SCHEDULE: { sec: 'cm', req: 'del_schedule' },
    GET_SCHEDULE_ATTACHMENTS: { sec: 'cm', req: 'get_schedule_attachments' },
    DEL_SCHEDULE_ATTACHMENT: { sec: 'cm', req: 'del_schedule_attachment' },
    GET_SCHEDULE_TOTALS_BY_MONTH: {
        sec: 'cm',
        req: 'get_schedule_totals_by_month',
    },

    GET_INVOICE_CATEGORIES: { sec: 'cm', req: 'get_invoice_categories' },
    ADD_INVOICE_CATEGORY: { sec: 'cm', req: 'add_invoice_category' },
    UPDATE_INVOICE_CATEGORY: { sec: 'cm', req: 'update_invoice_category' },
    DEL_INVOICE_CATEGORY: { sec: 'cm', req: 'del_invoice_category' },
    GET_SUMMARY: { sec: 'cm', req: 'get_summary' },

    // Righe
    GET_INVOICE_ROWS: { sec: 'cm', req: 'get_invoice_rows' },
    ADD_INVOICE_ROW: { sec: 'cm', req: 'add_invoice_row' },
    UPDATE_INVOICE_ROW: { sec: 'cm', req: 'update_invoice_row' },
    DEL_INVOICE_ROW: { sec: 'cm', req: 'del_invoice_row' },

    // Status
    GET_INVOICE_STATUSES: { sec: 'cm', req: 'get_invoice_statuses' },
    ADD_INVOICE_STATUS: { sec: 'cm', req: 'add_invoice_status' },
    UPDATE_INVOICE_STATUS: { sec: 'cm', req: 'update_invoice_status' },
    DEL_INVOICE_STATUS: { sec: 'cm', req: 'del_invoice_status' },
} as { [key: string]: SLSApiRequest }

// Components
import DefaultLayout from '@/modules/primevue/components/AppLayout.vue'
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/msg'

export const MessagingRouter: Array<RouteRecordRaw> = [
    {
        path: basePath,
        name: 'Home messaggistica',
        redirect: `${basePath}/chats`,
        component: DefaultLayout,
        children: [
            {
                path: `${basePath}/chats`,
                name: 'Chat index',
                meta: {
                    title: 'Chat',
                },
                component: () =>
                    import('@/modules/messaging/components/ChatIndex.vue'),
            },
            {
                path: `${basePath}/chat/:userId`,
                name: 'Chat con utente',
                meta: {
                    title: 'Chat',
                },
                component: () =>
                    import('@/modules/messaging/components/ChatIndex.vue'),
            },
        ],
    },
]

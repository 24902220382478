import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { booleanValue } from '@/modules/sls/helpers/sanitize-helpers'
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'
import axios from 'axios'

/**
 * Handles error and loading screen during axios request
 * @param cb CallableFunction
 * @param onSuccess CallableFunction
 * @param onError CallableFunction
 * @param rethrowError boolean
 */
export const axiosRequest = async (
    cb: CallableFunction,
    onSuccess?: CallableFunction,
    onError?: CallableFunction,
    rethrowError: boolean = true,
) => {
    const uiStore = useUiStore()
    const currentUserStore = useCurrentUserStore()

    uiStore.isLoading = true

    let cbData

    try {
        cbData = await cb()

        if (typeof onSuccess === 'function') {
            await onSuccess(cbData)
        }
    } catch (e: any) {
        if (axios.isCancel(e)) {
            throw e
        } else {
            let errorString: string =
                "Non è stato possibile completare l'operazione, riprova in seguito"
            if (!e.response) {
                errorString = e
            } else if (e.response.data?.error) {
                errorString = e.response.data.error
            } else if (e.response.data?.status) {
                errorString = e.response.datastatus
            } else if (
                typeof e.respose.data === 'string' &&
                e.response.data.length > 0
            ) {
                errorString = e.response.data
            }

            if (typeof onError === 'function') {
                await onError(e)
            } else if (currentUserStore.currentUser?.is_developer) {
                uiStore.ADD_TOAST({
                    severity: 'error',
                    detail: errorString,
                })
            }

            if (rethrowError) {
                throw e
            }
        }
    } finally {
        uiStore.isLoading = false
    }

    if (cbData?.data) return cbData.data
    if (cbData) return cbData
    return null
}

export const isItemVisible = (permissions: string | string[]) => () => {
    const currentUserStore = useCurrentUserStore()
    if (typeof permissions === 'string') {
        booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
            console.debug(
                '>>> isItemVisible',
                permissions,
                currentUserStore.HAS_PERMISSION(permissions),
            )
        return currentUserStore.HAS_PERMISSION(permissions)
    } else {
        booleanValue(import.meta.env.VITE_DEBUG_MENU) &&
            console.debug(
                '>>> isItemVisible',
                permissions,
                currentUserStore.HAS_PERMISSIONS(permissions),
            )
        return currentUserStore.HAS_PERMISSIONS(permissions)
    }
}

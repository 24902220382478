// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import type { RouteRecordRaw } from 'vue-router'

export const SLSRouter: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home dashboard',
        redirect: `/home`,
        component: AppLayout,
        children: [
            {
                path: `/home`,
                name: 'Home',
                meta: {
                    title: 'Home',
                },
                component: () => import('@/modules/sls/components/SLSHome.vue'),
            },
        ],
    },
]

import type { RouteRecordRaw } from 'vue-router'

const basePath = '/pr'

export default {
    path: `${basePath}/settings`,
    name: 'Impostazioni produzioni',
    meta: {
        title: 'Impostazioni produzioni',
    },
    component: () =>
        import('@/modules/production/components/ProductionSettings.vue'),
    children: [
        {
            path: 'locations',
            name: 'Gestione location',
            meta: {
                title: 'Gestione location',
                guides: [
                    {
                        url: 'https://www.guidejar.com/guides/68a85b98-4327-42fc-9f4f-53e839b6f6ef',
                        label: '1.12 - Aggiungere una location',
                    },
                ],
            },
            component: () =>
                import(
                    '@/modules/production/components/ProductionLocation.vue'
                ),
        },
        {
            path: 'production-statuses',
            name: 'Gestione status produzione',
            meta: {
                title: 'Gestione status produzione',
                guides: [],
            },
            component: () =>
                import(
                    '@/modules/production/components/ProductionStatusManage.vue'
                ),
        },
        {
            path: 'production-date-types',
            name: 'Gestione tipologie date produzione',
            meta: {
                title: 'Gestione tipologie date produzione',
                guides: [],
            },
            component: () =>
                import(
                    '@/modules/production/components/ProductionDateTypeManage.vue'
                ),
        },
    ],
} as RouteRecordRaw

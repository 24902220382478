import config from '@/config'

export const generateUrl = (
    parameters: { [key: string]: any },
    endpoint: string = config().ENDPOINT,
    protocol: string = 'https',
): string => {
    const urlParams = new URLSearchParams()

    Object.entries(parameters).forEach(([name, value]) => {
        if (typeof value === 'undefined' || value === null) return

        urlParams.set(name, value.toString())
    })

    // Base
    let url =
        protocol + '://' + window.location.hostname + window.location.pathname

    // Endpoint (evito doppio separatore /)
    url +=
        (endpoint[0] === '/' ||
        (window.location.pathname.length > 0 &&
            window.location.pathname[window.location.pathname.length - 1] ===
                '/')
            ? ''
            : '/') + endpoint

    // Parametri
    if (urlParams.toString().length > 0) {
        url += '?' + urlParams.toString()
    }

    console.debug('generateUrl', url, [
        protocol,
        window.location.hostname,
        window.location.pathname,
        endpoint,
        '?',
        urlParams.toString(),
    ])

    return url
}

export const generateDocument = (
    parameters: { [key: string]: any },
    target = '_blank',
) => {
    window.open(generateUrl(parameters), target)
}

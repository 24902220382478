<script setup lang="ts">
import { t } from '@/locales'
import { toPageLink } from '@/modules/address-book/helpers/links'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import SLSGuidesWidget from '@/modules/sls/components/SLSGuidesWidget.vue'
import { useInstanceStore } from '@/modules/sls/stores/instance-store'
import { toTechnicianLink } from '@/modules/technician/helpers/links'
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'
import router from '@/router'
import { differenceInDays, formatDate, isDate } from 'date-fns'
import Avatar from 'primevue/avatar'
import Button from 'primevue/button'
import OverlayPanel from 'primevue/overlaypanel'
import SelectButton from 'primevue/selectbutton'
import Tag from 'primevue/tag'
import { computed, onMounted, ref } from 'vue'
import { useWindowSize } from 'vue-window-size'
import AppSidebar from './AppSidebar.vue'

const uiStore = useUiStore()
const currentUserStore = useCurrentUserStore()
const instanceStore = useInstanceStore()

const { width: windowWidth } = useWindowSize()

// Menu
const buttonMenuIcon = computed(() =>
    uiStore.IS_SIDEBAR_ACTIVE() ? 'far fa-times' : 'far fa-bars',
)
const onMenuButtonClick = () => {
    uiStore.ON_MENU_TOGGLE()
}

// Cambio installazione
const installations = computed(() =>
    currentUserStore.userInstallations.map((id) => ({
        label: t('SLS.go_to_installation'),
        key: id,
        url: `https://madeinshow.app/${id}`,
        target: '_blank',
    })),
)
const op = ref()
const toggleInstallations = (event: any) => {
    op.value.toggle(event)
}

// Tema chiaro / scuro
const options = ref([
    { icon: 'fa-sun', value: 'light', label: 'light theme' },
    { icon: 'fa-moon', value: 'dark', label: 'dark theme' },
])
const switchSelectorClass = (slotProps: any, colorScheme: string) => {
    const iconType = colorScheme === slotProps.option.value ? 'fas' : 'far'

    const iconColor =
        colorScheme === slotProps.option.value ? 'primary-color' : ''
    return `${iconType} ${slotProps.option.icon} ${iconColor}`
}

// Font size
const fontSize = computed(() => uiStore.fontSize)
const colorScheme = computed(() => uiStore.colorScheme)

const changeColorScheme = (newColorScheme: any) => {
    uiStore.CHANGE_COLOR_SCHEME(newColorScheme.value)
}

const changeFontSize = (add: boolean) => {
    const newFontSize = parseInt(fontSize.value) + (add ? 1 : -1)

    if (availableFontSizes.value.includes(newFontSize)) {
        uiStore.SET_FONT_SIZE(`${newFontSize}px`)
    }
}

const availableFontSizes = ref([12, 13, 14, 15, 16])

// Profilo utente
const initials = computed(() => {
    if (!currentUserStore.currentUser?.user_id) {
        return ' '
    }
    const nameInitial = currentUserStore.currentUser.name.trim().length
        ? currentUserStore.currentUser.name.trim().substring(0, 1)
        : ''
    const surnameInitial = currentUserStore.currentUser.surname.trim().length
        ? currentUserStore.currentUser.surname.trim().substring(0, 1)
        : ''

    return nameInitial + surnameInitial
})

// Versione
const misVersion = computed(() => instanceStore.MIS_VERSION)
const changelogUrl = import.meta.env.VITE_CHANGELOG_URL

// Demo
const demoInfo = ref()
const toggleDemoInfo = (event: any) => {
    demoInfo.value.toggle(event)
}
const remainingDemoDays = computed(() =>
    isDate(instanceStore.DEMO_VERSION_EXPIRATION_DATE)
        ? differenceInDays(
              instanceStore.DEMO_VERSION_EXPIRATION_DATE,
              Date.now(),
          )
        : 0,
)
const lastDemoDay = computed(() =>
    isDate(instanceStore.DEMO_VERSION_EXPIRATION_DATE)
        ? formatDate(instanceStore.DEMO_VERSION_EXPIRATION_DATE, 'PPPP')
        : '',
)
const activateDemoUrl = computed(() => import.meta.env.VITE_ACTIVATE_DEMO_URL)

const goToHome = () => {
    router.push('/home')
    uiStore.SET_ACTIVE_MENU_ITEM('home')
}
const isHomeActive = computed(() => uiStore.activeMenuItem === 'home')

onMounted(() => {
    currentUserStore.GET_USER_INSTALLATIONS()
})
</script>
<template lang="pug">
#topbar.layout-topbar
    .topbar-start.gap-3.align-items-center
        Button.topbar-menubutton.p-link.p-trigger(
            ref='menubutton',
            type='button',
            @click='onMenuButtonClick()'
        )
            i(:class='buttonMenuIcon')
        .sls-home.topbar-item.m-0
            router-link.text-primary(:to='"/home"')
            Button(
                icon='far fa-home text-lg',
                rounded,
                severity='secondary',
                :title='$t("SLS.home")',
                @click='goToHome()',
                :class='{ "home-icon-active": isHomeActive }'
            )
        span.company-name.hidden.text-xl(class='lg:block lg:text-2xl')= instanceStore.COMPANY_NAME
        .topbar-item.m-0(v-if='installations.length > 1')
            Button(
                icon='far fa-exchange',
                @click='toggleInstallations',
                aria-haspopup='true',
                aria-controls='overlay_installations_menu',
                outlined,
                rounded,
                :title='$t("SLS.change")'
            )
            OverlayPanel(ref='op')
                .flex.flex-column.gap-3.w-21rem
                    div
                        ul.list-none.p-0.m-0.flex.flex-column
                            li.flex.align-items-center.gap-2(
                                v-for='(installation, index) in installations',
                                :key='installation.key',
                                :index='index'
                            )
                                a.w-full(
                                    :href='installation.url',
                                    :target='installation.target'
                                )
                                    .flex.align-items-center.flex-row.w-full.justify-content-between
                                        .font-medium #{ t('SLS.open') } #{ installation.key }
                                        Button(
                                            icon='far fa-external-link',
                                            outlined
                                        )
                                    if (index < installations.length - 1)
                                        hr

    .layout-topbar-menu-section
        AppSidebar
    .topbar-end
        ul.topbar-menu.m-0.p-0.column-gap-4
            li.hidden.m-0(v-if='currentUserStore.asUserActive', class='md:block')
                Button.px-2.py-1(
                    icon='far fa-user-ninja',
                    severity='info',
                    label='Esci',
                    @click='currentUserStore.AS_USER_DISABLE()'
                )

            li.m-0.li-offline(v-if='uiStore.offline')
                Tag.px-2.py-1(
                    icon='far fa-spinner text-xl fa-pulse',
                    severity='warning',
                    :value='$t("SLS.offline")'
                )
            li.m-0.li-demo-info(v-if='instanceStore.DEMO_VERSION')
                Button(
                    icon='far fa-info-circle text-xl',
                    @click='toggleDemoInfo',
                    aria-haspopup='true',
                    aria-controls='overlay_demo_info_menu',
                    outlined,
                    :severity='remainingDemoDays <= 15 ? "warning" : "info"',
                    :label='windowWidth > 992 ? $t("SLS.activate_demo_cta", { remainingDemoDays }) : $t("SLS.activate_demo_cta_short", { remainingDemoDays })'
                )
                OverlayPanel(ref='demoInfo')
                    .flex.flex-column.gap-5.w-20rem
                        .block.text-balance.text-xl= $t('SLS.activate_demo_info', { lastDemoDay })
                        a(:href='activateDemoUrl', target='_blank')
                            Button(
                                icon='far fa-calendar',
                                :label='$t("SLS.check_our_availability")'
                            )

            li.m-0.li-guides
                SLSGuidesWidget

            li#topbar-profile-item.profile-item.topbar-item.m-0(ref='profile')
                a.cursor-pointer(
                    v-styleclass='{ selector: "@next", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }',
                    ripple
                )
                    Avatar(:label='initials', shape='circle', size='large')
                ul.topbar-menu.active-topbar-menu.p-4.w-20rem.z-5.hidden.border-round
                    h6.text-right.text-balance.font-bold(class='md:hidden')= instanceStore.COMPANY_NAME
                    hr(class='md:hidden')
                    li.m-0.py-2(
                        role='menuitem',
                        v-if='currentUserStore.currentUser?.technician_id'
                    )
                        router-link.flex.align-items-center.transition-duration-200(
                            :to='toTechnicianLink(currentUserStore.currentUser?.technician_id ?? 0)',
                            class='hover:text-primary-500',
                            v-styleclass='{ selector: "@grandparent", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }'
                        )
                            i.far.fa-user-hard-hat.mr-1
                            span= $t('TC.open_your_technician_sheet')
                    li.m-0.py-2(
                        role='menuitem',
                        v-if='currentUserStore.currentUser?.personal_data_id'
                    )
                        router-link.flex.align-items-center.transition-duration-200(
                            :to='toPageLink(currentUserStore.currentUser?.personal_data_id ?? 0)',
                            class='hover:text-primary-500',
                            v-styleclass='{ selector: "@grandparent", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }'
                        )
                            i.far.fa-address-book.mr-1
                            span= $t('AB.open_your_sheet')
                    li.m-0.py-2(role='menuitem')
                        a.flex.align-items-center.transition-duration-200(
                            href='https://foh.madeinshow.app/live',
                            class='hover:text-primary-500',
                            v-styleclass='{ selector: "@grandparent", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }'
                        )
                            i.far.fa-sign-out.mr-1
                            span= $t('SLS.go_to_front_of_house')

                    if currentUserStore.asUserActive
                        hr

                        li.m-0.py-2(
                            role='menuitem',
                            class='md:hidden',
                            v-if='currentUserStore.asUserActive'
                        )
                            a.flex.align-items-center.transition-duration-200(
                                href='#',
                                @click='currentUserStore.AS_USER_DISABLE()',
                                class='hover:text-primary-500',
                                v-styleclass='{ selector: "@grandparent", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }'
                            )
                                i.far.fa-user-ninja.mr-1
                                span= t('SLS.go_back_to_your_user')

                    hr

                    li#topbar-theme-switch.topbar-item.block.ml-0
                        h6= $t('SLS.theme')
                        SelectButton.mt-2(
                            model-value='colorScheme',
                            @update:modelValue='changeColorScheme',
                            :options='options',
                            aria-labelledby='dark',
                            optionlabel='label',
                            datakey='value'
                        )
                            template(#option='slotProps')
                                i(
                                    :class='switchSelectorClass(slotProps, colorScheme)'
                                )
                    li.topbar-item.block.mt-3.ml-0
                        h6= $t('SLS.interface_size')
                        .inline-flex.gap-2.border-1.surface-border.align-items-center.mt-2.py-1.px-2(
                            style='border-radius: 30px'
                        )
                            Button.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-text(
                                @click='changeFontSize(false)',
                                icon='far fa-minus text-xs',
                                data-pc-name='button',
                                data-pc-section='root'
                            )
                            i.far.fa-circle.text-sm.text-200(
                                :class='{ "text-lg text-primary": fontSize == `${item}px` }',
                                v-for='item in availableFontSizes'
                            )
                            Button.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-text(
                                @click='changeFontSize(true)',
                                type='button',
                                icon='far fa-plus text-xl',
                                data-pc-name='button',
                                data-pc-section='root',
                                data-pd-ripple='true'
                            )

                    hr

                    li.topbar-item.block.mt-3.ml-0
                    .text-lg.mb-1 Made In Show #{ misVersion }
                    a.link-blank-icon.text-sm(
                        :href='changelogUrl',
                        target='_blank'
                    )= $t('SLS.open_changelog')
</template>

<style lang="scss" scoped>
::v-deep(.layout-megamenu) {
    background: none;

    .p-megamenu-root-list {
        .p-menuitem-link:not(.p-submenu-list .p-menuitem-link) {
            .p-menuitem-text,
            .p-menuitem-icon,
            .p-submenu-icon {
                color: var(--topbar-item-text-color) !important;
            }

            .layout-megamenu:not(.p-disabled):hover {
                background: var(--topbar-item-hover-bg);
                transition: background-color var(--transition-duration);
            }
        }
    }
}
</style>

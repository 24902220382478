// Components
import DefaultLayout from '@/modules/primevue/components/AppLayout.vue'
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/dev'

export const DevRouter: Array<RouteRecordRaw> = [
    {
        path: basePath,
        name: 'DEV',
        redirect: '/',
        component: DefaultLayout,
        children: [
            {
                path: `${basePath}/application-log`,
                name: 'Log applicazione',
                meta: {
                    title: 'Log applicazione',
                },
                component: () =>
                    import('@/modules/dev/components/DevApplicationLog.vue'),
            },
        ],
    },
]

import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/pr'

export default {
    path: `${basePath}/production/:id`,
    name: 'Produzione',
    meta: {
        // title: '◌',
    },
    redirect: (to) => removeTrailingSlash(to.fullPath) + '/general',
    component: () =>
        import(
            '@/modules/production/components/production/ProductionSheet.vue'
        ),
    children: [
        {
            path: 'general',
            name: 'Scheda produzione generale',
            meta: {
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/3b6b09d4-ebac-4482-8474-febf11447b47',
                        label: '1.3 - Modifica dati generali di una produzione',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/3d6c008c-614d-43ab-a67a-4fe0e9b75deb',
                        label: '1.4 - Spostamento date del periodo di lavorazione di una produzione',
                    },
                ],
            },
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionGeneral.vue'
                ),
        },
        {
            path: 'dates-management',
            name: 'Scheda gestione date',
            meta: {
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/ec633273-a566-46e4-ac35-3dd98dee06ea',
                        label: '1.5 - Modifica delle tipologie di date di una produzione',
                    },
                ],
            },
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionDatesManagement.vue'
                ),
        },
        {
            path: 'teams',
            name: 'Scheda gestione squadre produzione',
            meta: {
                guides: [
                    {
                        url: 'https://www.guidejar.com/guides/584cf84f-a1a0-48ed-a21f-da63b86e6e05',
                        label: '1.13 - Creazione di una composizione squadra',
                    },
                ],
            },
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionTeamCompositionIndex.vue'
                ),
        },
        {
            path: 'team/:tcsId',
            name: 'Scheda gestione squadra produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionTeamComposition.vue'
                ),
        },
        {
            path: 'data-import',
            name: 'Scheda importazione dati',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionDataImport.vue'
                ),
        },
        {
            path: 'data-export',
            name: 'Scheda esportazione dati',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionDataExport.vue'
                ),
        },
        {
            path: 'summary-by-date',
            name: 'Scheda riassunto per data',
            meta: {
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/7d359a22-955a-486c-b876-40f2686aa11d',
                        label: '1.14 - Riassunto per data',
                    },
                ],
            },
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionSummaryByDate.vue'
                ),
        },
        {
            path: 'economic-details',
            name: 'Dettagli economici',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionEconomicDetails.vue'
                ),
        },
        {
            path: 'products-list',
            name: 'Materiale a magazzino',
            meta: {
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/ec173fdf-17e2-4fc3-b9c3-f05f7c188b57',
                        label: '1.6 - Prenotare una tipologia di prodotto a magazzino su una produzione',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/e4798be0-f361-4f70-a2f4-c85696a6c221',
                        label: '1.7 - Modificare o cancellare una prenotazione di prodotto a magazzino',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/ba5e2908-73f6-4dad-b29d-506fa2811ead',
                        label: '1.8 - Aggiungere un budget/extra di materiale su una produzione',
                    },
                ],
            },
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionProductReservations.vue'
                ),
        },
        {
            path: 'product-specs',
            name: 'Specifiche lista materiale',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionProductSpecs.vue'
                ),
        },
        {
            path: 'staging-blocks',
            name: 'Scheda gestione blocchi scenografici produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionStagingIndex.vue'
                ),
        },
        {
            path: 'staging-block/:stagBlockId',
            name: 'Scheda gestione blocco scenografico produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionStagingBlock.vue'
                ),
        },
        {
            path: 'technicians-list',
            name: 'Prenotazioni tecnici in produzione',
            meta: {
                guides: [
                    {
                        url: 'https://howto.madeinshow.app/guides/cdb88f64-8b6a-40b1-967c-9617a6658377',
                        label: '1.9 - Prenotare un tecnico su una produzione',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/c382c947-9c57-49aa-b645-7dd58a81b016',
                        label: '1.10 - Modificare o cancellare una prenotazione di tecnico su una produzione',
                    },
                    {
                        url: 'https://howto.madeinshow.app/guides/7a7996eb-7bf7-49e3-8a17-c28e88c69275',
                        label: '1.11 - Aggiungere un budget/extra di servizi tecnici su una produzione',
                    },
                ],
            },
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionTechnicianReservations.vue'
                ),
        },
        {
            path: 'dn',
            name: 'Scheda Documento di Trasporto',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionDeliveryNotes.vue'
                ),
        },
        {
            path: 'notes',
            name: 'Note Produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionNotes.vue'
                ),
        },
        {
            path: 'attachments',
            name: 'Allegati Produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionAttachments.vue'
                ),
        },
        {
            path: 'estimate',
            name: 'Preventivo Produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionEstimate.vue'
                ),
        },
        {
            path: 'company-vehicles',
            name: 'Veicoli aziendali Produzione',
            component: () =>
                import(
                    '@/modules/production/components/company-vehicle/ProductionCompanyVehicleReservations.vue'
                ),
        },
        {
            path: 'ee/:entryTypeId',
            name: 'Preventivi esterni in produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionExtEntry.vue'
                ),
        },
    ],
} as RouteRecordRaw

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'

import axios from 'axios'
import axiosRetry from 'axios-retry'
axiosRetry(axios, {
    retries: 3,
    retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
    onRetry(retryCount, error, requestConfig) {
        console.debug('axios retry', retryCount, error, requestConfig)
    },
})

axios.defaults.withCredentials = true
axios.interceptors.response.use(
    (response) => {
        // Status 2xx
        uiStore.offline = false
        return response
    },
    (error) => {
        // Status diverso da 2xx
        if (error.code === 'ERR_NETWORK') {
            uiStore.offline = true
        }

        // Demo scaduta
        if (error?.response?.status === 402) {
            window.location.href = import.meta.env.VITE_ACTIVATE_DEMO_URL
            return
        }
        return Promise.reject(error)
    },
)

import i18nPlugin from '@/locales'
import { booleanValue } from '@/modules/sls/helpers/sanitize-helpers'
import pinia from '@/store'
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'

//import helpers from './plugins/helpers'

const app = createApp(App)

// Telemetria
import { sentryInit } from '@/telemetry/sentry'

if (booleanValue(import.meta.env.VITE_SENTRY_ENABLED)) {
    sentryInit(app)
}

app.use(i18nPlugin)
app.use(pinia)
app.use(router)
app.use(PrimeVue, { ripple: true })
app.use(ToastService)
app.use(ConfirmationService)
//app.use(helpers)
app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.directive('styleclass', StyleClass)

// Abilita o disabilita console.debug
const urlParams = new URLSearchParams(window.location.search)

if (!urlParams.has('debug') && !import.meta.env.DEV) {
    console.debug = () => {
        //
    }
}

// Online - offline
import { useUiStore } from '@/modules/primevue/stores/ui-store'

const uiStore = useUiStore()
window.addEventListener('offline', () => {
    console.log("Show a You're offline. toast.")
    uiStore.offline = true
})

window.addEventListener('online', () => {
    console.log("Clear the You're offline toast, if it's open.")
    uiStore.offline = false
})

app.mount('#app-container')

// Components
import DefaultLayout from '@/modules/primevue/components/AppLayout.vue'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'
import type { RouteRecordRaw } from 'vue-router'

const basePath = '/cv'

export const CompanyVehiclesRouter: Array<RouteRecordRaw> = [
    {
        path: basePath,
        name: 'Home veicoli aziendali',
        redirect: `${basePath}/index`,
        component: DefaultLayout,
        children: [
            {
                path: `${basePath}/index/:isAdd?`,
                name: 'Veicoli aziendali',
                meta: {
                    title: 'Elenco veicoli aziendali',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/b16285da-d59e-4466-8ad6-6cb04858f668',
                            label: '4.1 - Aggiungere un nuovo veicolo aziendale',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/company-vehicle/components/CompanyVehicleIndex.vue'
                    ),
            },

            {
                path: `${basePath}/planner`,
                name: 'Veicoli aziendali planner',
                meta: {
                    title: 'Planner veicoli aziendali',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/a5ac38f5-9fc2-4194-9f78-f173b4a702ca',
                            label: '4.2 - Planner veicoli aziendali',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/company-vehicle/components/CompanyVehiclesPlanner.vue'
                    ),
            },
            {
                path: `${basePath}/vehicle/:id`,
                name: 'Veicolo aziendale',
                meta: {
                    // title: '◌',
                },
                redirect: (to) => removeTrailingSlash(to.fullPath) + '/general',
                component: () =>
                    import(
                        '@/modules/company-vehicle/components/CompanyVehicleSheet.vue'
                    ),
                children: [
                    {
                        path: 'general',
                        name: 'Scheda veicolo aziendale generale',
                        component: () =>
                            import(
                                '@/modules/company-vehicle/components/CompanyVehicleGeneral.vue'
                            ),
                    },
                    {
                        path: 'attachments',
                        name: 'Allegati veicoli aziendali',
                        component: () =>
                            import(
                                '@/modules/company-vehicle/components/CompanyVehicleAttachments.vue'
                            ),
                    },
                ],
            },
        ],
    },
]

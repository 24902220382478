// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'
import type { RouteRecordRaw } from 'vue-router'
import technicianSettings from './_settings'
import technicianStats from './_stats'

const basePath = '/tc'

let routes: Array<RouteRecordRaw> = [
    {
        path: basePath,
        name: 'Tecnici',
        redirect: `${basePath}/index`,
        component: AppLayout,
        children: [
            {
                path: `${basePath}/index`,
                name: 'Elenco tecnici',
                meta: {
                    title: 'Elenco tecnici',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/fed7c482-d9d2-4e74-a762-9b738a8f61d5',
                            label: '3.1 - Ricercare un tecnico',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/5039db5d-8e51-4775-b4ef-ad6349856d1c',
                            label: '3.3 - Aggiungere una tariffa ad un tecnico',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/0905868e-a547-4970-ae4d-1740d7ee2278',
                            label: '3.4 - Nascondere una scheda tecnico dalle ricerche',
                        },
                    ],
                },
                component: () =>
                    import(
                        '@/modules/technician/components/TechniciansIndex.vue'
                    ),
            },
            {
                path: `${basePath}/add`,
                name: 'Aggiungi tecnico',
                meta: {
                    title: 'Aggiungi tecnico',
                    guides: [
                        {
                            url: 'https://howto.madeinshow.app/guides/348c0371-625d-4442-acde-ad7455b70233',
                            label: '3.2 - Aggiungi tecnico: se presente in anagrafica',
                        },
                        {
                            url: 'https://howto.madeinshow.app/guides/7f166dad-23e7-42f3-b32f-9d78ebd83366',
                            label: '3.2.1 - Aggiungi tecnico: se presente non presente in anagrafica',
                        },
                    ],
                },
                component: () =>
                    import('@/modules/technician/components/TechnicianAdd.vue'),
            },
            {
                path: `${basePath}/planner`,
                name: 'Planner tecnici',
                meta: {
                    title: 'Planner tecnici',
                },
                component: () =>
                    import(
                        '@/modules/technician/components/TechniciansPlanner.vue'
                    ),
            },
            {
                path: `${basePath}/roles-planner`,
                name: 'Planner tecnici per competenza',
                meta: {
                    title: 'Planner tecnici per competenza',
                },
                component: () =>
                    import(
                        '@/modules/technician/components/TechnicianRolesPlanner.vue'
                    ),
            },

            // Statistiche
            technicianStats,

            // Impostazioni
            technicianSettings,

            {
                path: `${basePath}/:id`,
                name: 'Scheda tecnico',
                meta: {
                    // title: '◌',
                },
                redirect: (to) => removeTrailingSlash(to.fullPath) + '/general',
                component: () =>
                    import(
                        '@/modules/technician/components/TechnicianSheet.vue'
                    ),
                children: [
                    {
                        path: 'general',
                        name: 'Scheda tecnico generale',
                        component: () =>
                            import(
                                '@/modules/technician/components/TechnicianSheetAddressBook.vue'
                            ),
                    },
                    {
                        path: 'planner',
                        name: 'Scheda tecnico planner',
                        component: () =>
                            import(
                                '@/modules/technician/components/TechnicianSheetPlanner.vue'
                            ),
                    },
                    {
                        path: 'invites-planner',
                        name: 'Scheda tecnico inviti planner',
                        component: () =>
                            import(
                                '@/modules/technician/components/TechnicianSheetInvitesPlanner.vue'
                            ),
                    },
                    {
                        path: 'technician-data',
                        name: 'Scheda tecnico dati tecnico',
                        meta: {
                            guides: [
                                {
                                    url: 'https://howto.madeinshow.app/guides/0905868e-a547-4970-ae4d-1740d7ee2278',
                                    label: '3.4 - Nascondere una scheda tecnico dalle ricerche',
                                },
                            ],
                        },
                        component: () =>
                            import(
                                '@/modules/technician/components/TechnicianSheetGeneral.vue'
                            ),
                    },
                    {
                        path: 'working-report',
                        name: 'Scheda tecnico resoconto mensile',
                        component: () =>
                            import(
                                '@/modules/technician/components/TechnicianSheetWorkingReport.vue'
                            ),
                    },
                    {
                        path: 'price-list',
                        name: 'Scheda tecnico competenze e compensi correnti',
                        component: () =>
                            import(
                                '@/modules/technician/components/TechnicianSheetPriceList.vue'
                            ),
                    },
                    {
                        path: 'manage-price-list',
                        name: 'Scheda tecnico gestione competenze e compensi correnti',
                        meta: {
                            guides: [
                                {
                                    url: 'https://howto.madeinshow.app/guides/5039db5d-8e51-4775-b4ef-ad6349856d1c',
                                    label: '3.3 - Aggiungere una tariffa ad un tecnico',
                                },
                            ],
                        },
                        component: () =>
                            import(
                                '@/modules/technician/components/TechnicianSheetPriceManager.vue'
                            ),
                    },
                    {
                        path: 'attachments',
                        name: 'Scheda tecnico allegati',
                        component: () =>
                            import(
                                '@/modules/technician/components/TechnicianSheetAttachments.vue'
                            ),
                    },
                ],
            },
        ],
    },
]

export const TechniciansRouter: Array<RouteRecordRaw> = routes

<template lang="pug">
Button(
    icon='far fa-question text-xl',
    aria-haspopup='true',
    aria-controls='guides_menu',
    outlined,
    severity='info',
    :label='windowWidth < 992 ? "" : $t("SLS.help")',
    :badge='pageGuideBadges',
    badgeSeverity='info',
    @click='togglePopup'
)
OverlayPanel(ref='guides')
    .flex.flex-column.gap-3.w-21rem
        .flex.align-items-center(v-for='guide in pageGuides')
            a.flex.align-items-center.transition-duration-200(
                :href='guide.url',
                target='_blank',
                class='hover:text-primary-500',
                v-styleclass='{ selector: "@grandparent", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }'
            )
                i.mr-3(:class='guide.icon ? guide.icon : "far fa-bookmark"')
                span= guide.label
        if pageGuides.length
            hr.my-0
        a.flex.align-items-center.transition-duration-200(
            href='https://howto.madeinshow.app/',
            target='_blank',
            class='hover:text-primary-500',
            v-styleclass='{ selector: "@grandparent", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }'
        )
            i.far.fa-book-open.mr-3
            span= $t('SLS.guides')
        a.flex.align-items-center.transition-duration-200(
            href='https://howto.madeinshow.app/categories/troubleshooting',
            target='_blank',
            class='hover:text-primary-500',
            v-styleclass='{ selector: "@grandparent", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }'
        )
            i.far.fa-rocket.mr-3
            span= $t('SLS.troubleshooting')
        a.flex.align-items-center.transition-duration-200(
            href='mailto:team@synclab.studio',
            target='_blank',
            class='hover:text-primary-500',
            v-styleclass='{ selector: "@grandparent", enterClass: "hidden", enterActiveClass: "px-scalein", leaveToClass: "hidden", leaveActiveClass: "px-fadeout", hideOnOutsideClick: true }'
        )
            i.far.fa-headphones-alt.mr-3
            span= $t('SLS.assistance')
</template>
<script setup lang="ts">
import Avatar from 'primevue/avatar'
import Button from 'primevue/button'
import OverlayPanel from 'primevue/overlaypanel'

import { t } from '@/locales'

import router from '@/router'
import { computed, ref } from 'vue'
import { useWindowSize } from 'vue-window-size'

const { width: windowWidth } = useWindowSize()

type Guide = {
    url?: string
    label?: string
    icon?: string
}

const guides = ref()
const togglePopup = (event: any) => {
    guides.value.toggle(event)
}

const pageGuides = computed(
    () => (router.currentRoute.value?.meta?.guides ?? []) as Guide[],
)

const pageGuideBadges = computed(() =>
    pageGuides.value.length ? pageGuides.value.length.toString() : undefined,
)
</script>
